import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { FaCheck } from "react-icons/fa";
import { logoutUser } from "../../../../redux/actions/authActions";
import store from "../../../../redux/store";
import { updateContactFreshworks } from "../../../../redux/actions/userActions";

const Step = () => {
  useEffect(() => {
    updateContactFreshworks({
      lifecycle: "SIGN_UP_APPLICATION_COMPLETED",
    }).catch(err => console.log(err));

    /* Facebook pixel event */
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("trackCustom", "OnboardingApplication", { onboarded: true });
      }
    }
    /* Facebook pixel event END */
  }, []);

  const handleApplicationComplete = () => {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/";
  };

  return (
    <>
      <h1 className={classes.h1}>Application complete</h1>

      <Card className={classes.card}>
        <Card.Body className="verifiedEmail">
          <div className="circle success checkmarkAnimation">
            <FaCheck className="checkmark" />
          </div>

          <div
            className={["text-center mt-5 mb-4", classes.regCompleteText].join(
              " "
            )}
          >
            Application complete!
          </div>

          <div
            className={["text-center mx-auto", classes.regCompleteSubtext].join(
              " "
            )}
          >
            Thank you for completing your application. Please{" "}
            <Button
              variant="link"
              className="orangeLink"
              onClick={handleApplicationComplete}
            >
              login
            </Button>{" "}
            to proceed to the next steps.
          </div>

          <div className="d-flex justify-content-around">
            <Button
              type="submit"
              className="blueButton my-5"
              onClick={handleApplicationComplete}
            >
              LOGIN
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Step;
