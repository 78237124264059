import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../../config/config";
import { Container, Row, Col, Button } from "react-bootstrap";
import setAuthToken from "../../../redux/utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setCurrentUser } from "../../../redux/actions/authActions";
import store from "../../../redux/store";
import { clearLocalStorage } from "../../../utils/utils";

class ConfirmEmail extends Component {
  state = {
    confirming: true,
    emailMessage: "",
  };

  componentDidMount = () => {
    const { id } = this.props.match.params;

    fetch(`${API_URL}/email/confirm/${id}`)
      .then(res => res.json())
      .then(data => {
        if (data.autologin) {
          // Remove registration steps data if exists
          console.log("CLEARING localStorage at email confirm");
          clearLocalStorage();

          // Save to localStorage
          // Set token to localStorage
          const { token } = data;
          localStorage.setItem("jwtToken", token);
          // Set token to Auth header
          setAuthToken(token);
          // Decode token to get user data
          const decoded = jwt_decode(token);
          // Set current user
          store.dispatch(setCurrentUser(decoded));
          localStorage.setItem("userData", JSON.stringify(decoded));
          localStorage.setItem("step", "1");
          localStorage.setItem("substep", "3");

          window.location.href = "/register";
        }

        this.setState({ confirming: false, emailMessage: data.msg });
      })
      .catch(err => console.log(err));
  };

  render = () => (
    <>
      {this.state.confirming ? null : (
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg="6" className="text-center">
              <br />
              <br />
              <p>{this.state.emailMessage}</p>
              <br />
              <br />
              <div className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  as={Link}
                  to="/"
                  className="blueButton"
                >
                  LOG IN
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default ConfirmEmail;
