import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { editUserWeb } from "../../../../redux/actions/authActions";
import Select from "react-select";
import {
  getBirthDaysReactSelect,
  getBirthMonthsReactSelect,
  getBirthYearsReactSelect,
} from "../../../../utils/utils";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!!localStorage.userData) {
      setUserData(prevState => {
        return {
          ...prevState,
          ...JSON.parse(localStorage.userData),
        };
      });
    }
  }, []);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 2) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(1);
      }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleChange = (e, target) => {
    let name;
    let value;

    if (
      target === "birthDay" ||
      target === "birthMonth" ||
      target === "birthYear"
    ) {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setUserData(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    setDisableButton(true);

    const editUser = {
      step: 2,
      subStep: 2,
      userId: props.auth.user.id,
      birthDay: userData.birthDay,
      birthMonth: userData.birthMonth,
      birthYear: userData.birthYear,
    };

    props.editUserWeb(editUser);
  };

  return (
    <>
      {isMobileOnly ? (
        <>
          <h1 className={classes.h1}>Personal details</h1>
          <h2 className={classes.h2}>Date of birth</h2>
        </>
      ) : (
        <h1 className={classes.h1}>Date of birth</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className={classes.label}>Day</Form.Label>
                <Select
                  name="birthDay"
                  required
                  onChange={e => handleChange(e, "birthDay")}
                  options={getBirthDaysReactSelect()}
                  value={{
                    label: userData.birthDay || "",
                    value: userData.birthDay || "",
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className={classes.label}>Month</Form.Label>
                <Select
                  name="birthMonth"
                  required
                  onChange={e => handleChange(e, "birthMonth")}
                  options={getBirthMonthsReactSelect()}
                  value={{
                    label: userData.birthMonth || "",
                    value: userData.birthMonth || "",
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className={classes.label}>Year</Form.Label>
                <Select
                  name="birthYear"
                  required
                  onChange={e => handleChange(e, "birthYear")}
                  options={getBirthYearsReactSelect()}
                  value={{
                    label: userData.birthYear || "",
                    value: userData.birthYear || "",
                  }}
                />
              </Form.Group>
            </Form.Row>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
              {errors.birthDay}
              {errors.birthMonth}
              {errors.birthYear}
            </Form.Text>
            <Button
              variant="link"
              className="simpleBackButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="blueButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  editUserWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { editUserWeb })(Step);
