import React, { useEffect } from "react";

const FacebookEvents = props => {
  useEffect(() => {
    /* Facebook pixel event */
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        switch (props.match.params.code) {
          case "KYCNotApproved":
            console.log("KYCNotApproved");
            window.fbq("trackCustom", "KYCStatus", { approved: false });
            break;
          case "KYCApproved":
            console.log("KYCApproved");
            window.fbq("trackCustom", "KYCStatus", { approved: true });
            break;
          case "UnfundedAccountDormant2Weeks":
            console.log("UnfundedAccountDormant2Weeks");
            window.fbq("trackCustom", "Account", {
              funded: false,
              dormant: true,
            });
            break;
          case "FundedAccountDormant2Weeks":
            console.log("FundedAccountDormant2Weeks");
            window.fbq("trackCustom", "Account", {
              funded: true,
              dormant: true,
            });
            break;
          case "InitialAccountDeposit":
            console.log("InitialAccountDeposit");
            window.fbq("trackCustom", "Account", {
              funded: true,
              amount: props.match.params.extra,
            });
            break;
          case "test":
            console.log("test");
            window.fbq("trackCustom", "EmailVerification", { verified: true });
            break;
          default:
        }
      }
    }
    /* Facebook pixel event END */
  }, []);

  return <h1>{props.match.params.code}</h1>;
};

export default FacebookEvents;
