import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import {
  checkEmailVerificationCode,
  resendVerificationEmail,
} from "../../../../redux/actions/authActions";
import emailIcon from "../../../../assets/images/email.svg";
import {
  clearLocalStorage,
  encryptData,
  encryptDataBase64,
} from "../../../../utils/utils";
import { updateContactFreshworks } from "../../../../redux/actions/userActions";
import { APP_URL } from "../../../../config/config";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [code, setCode] = useState(new Array(6).fill(""));
  const submitButtonRef = useRef(null);

  useEffect(() => {
    if (localStorage.userData) {
      // Set temporary userData to store ID and email
      setUserData({
        userId: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
      });
    }

    if (props.auth.user.showVerifyEmail) {
      // Remove registration steps
      console.log("CLEARING localStorage at email sent");
      clearLocalStorage();

      updateContactFreshworks({
        lifecycle: "EMAIL_VERIFICATION_NOT_VERIFIED",
      }).catch(err => console.log(err));
    }

    /* Facebook pixel event */
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("trackCustom", "EmailVerification", { verified: "new" });
      }
    }
    /* Facebook pixel event END */
  }, []);

  useEffect(() => {
    if (isCodeFullyEntered()) {
      submitButtonRef.current.click();
    }
  }, [code]);

  const isCodeFullyEntered = () => {
    return code.every(value => value !== "");
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return; // Ensure only numbers are entered

    setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on next input if value is not empty
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = e => {
    // Get pasted data
    const pasteData = e.clipboardData.getData("text");

    // Check if pasted data is 6 characters long
    if (pasteData.length === 6) {
      // Prevent the default paste action
      e.preventDefault();

      // Split the pasted data into individual characters
      const characters = pasteData.split("");

      // Create a new array by replacing the values in the `code` array with the pasted characters
      const updatedCode = [...code]; // Copy the current code array

      characters.forEach((char, idx) => {
        if (idx < updatedCode.length) {
          updatedCode[idx] = char;
        }
      });

      // Now, update the state with the new code values at once
      setCode(updatedCode); // Assuming `setCode` is your state setter
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const emailCode = code.join("");
    if (emailCode.length < 6) return false;

    checkEmailVerificationCode({
      ...userData,
      code: emailCode,
    })
      .then(response => {
        if (response.data.success) {
          window.location.href = `${APP_URL}/email/confirm/${encryptDataBase64(
            encryptData(userData.userId)
          )}`;
        } else alert(response.data.message);
      })
      .catch(err => console.log(err));
  };

  const handleResendEmail = () => {
    if (window.confirm("Press OK to send the verification email again.")) {
      props.resendVerificationEmail(userData.email);
    }
  };

  return (
    <>
      <h1 className={classes.h1}>Verify your email</h1>

      <Card className={classes.card}>
        <Card.Body>
          <img className={classes.emailIcon} src={emailIcon} alt="Email" />

          <p className={classes.emailText}>
            We sent a verification email to{" "}
            <strong>{userData.email || "email"}</strong>. Enter the 6-digit
            verification code we sent to proceed to the next step!
          </p>

          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center">
              {code.map((data, index) => {
                return (
                  <input
                    type="text"
                    name="code"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={e => handleChange(e.target, index)}
                    onFocus={e => e.target.select()}
                    onPaste={e => handlePaste(e)}
                    className="form-control m-1 py-4 text-center"
                    style={{ width: "50px" }}
                  />
                );
              })}
            </div>
            <button
              type="submit"
              className={classes.blueButton}
              ref={submitButtonRef}
            >
              Verify Code
            </button>
          </form>

          <Button
            variant="link"
            className={classes.emailResend}
            onClick={() => handleResendEmail()}
          >
            Email did not arrive?
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  resendVerificationEmail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { resendVerificationEmail })(Step);
