import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import {
  checkAllTradingAccountBalances,
  getLiveAccounts,
} from "../../../../redux/actions/userActions";
import NoFundedAccounts from "../../../../components/Modals/NoFundedAccounts/NoFundedAccountsWithdrawal";

const Step = props => {
  const [loading, setLoading] = useState(true);
  const [accountsLive, setLiveAccounts] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    checkAllTradingAccountBalances()
      .then(() => {
        setTimeout(() => {
          getLiveAccounts("approved", "all", true)
            .then(res => {
              let allZero = true;
              Object.entries(res.data).map(account => {
                if (parseFloat(account[1].balance) > 0) {
                  allZero = false;
                }
              });

              setLiveAccounts(res.data);
              setLoading(false);
              setShowModal(allZero);
            })
            .catch(err => console.log(err));
        }, 10000);
      })
      .catch(err => console.log(err));
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Row className="mx-1 mx-md-4 greyBg bottomBorders">
      <Col className="py-5">
        {/*<div className="wizardStepsParagraph">*/}
        {/*  <strong className="text-danger">*/}
        {/*    Withdrawals are temporarily in testing phase. Please check back later.*/}
        {/*  </strong>*/}
        {/*</div>*/}

        {accountsLive.length > 0 ? (
          <div className="wizardStepsParagraph">
            Please select the account that you would like to Withdraw Funds
            from:
          </div>
        ) : null}

        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner size="sm" animation="border" />
            <span className="pl-2">Checking accounts. Please wait...</span>
          </div>
        ) : null}

        {accountsLive.length > 0 ? (
          <ul className="list-unstyled wizardAccountList">
            {Object.entries(accountsLive).map(account => (
              <li
                key={account[1].number}
                className={account[1].type === "WALLET" ? "order-0" : "order-1"}
              >
                <div className="cardBorder red" />

                <span className="cardFlag accTypeWizard mt-3 ml-2 mr-5">
                  {account[1].type === "WALLET" ? "WALLET" : "LIVE"}
                </span>

                <span className="ml-2 mr-5">
                  <div className="cardCaptionTitle">
                    {account[1].type === "WALLET" ? "WALLET" : "ACCOUNT"} NAME
                  </div>
                  {account[1].nickname}
                </span>

                {account[1].type !== "WALLET" ? (
                  <>
                    <span className="ml-2 mr-5" style={{ minWidth: "85px" }}>
                      <div className="cardCaptionTitle">ACCOUNT NUMBER</div>
                      <div>{account[1].number || "-"}</div>
                    </span>

                    <span className="ml-2 mr-5">
                      <div className="cardCaptionTitle">PLATFORM</div>
                      {account[1].platform}{" "}
                      <small style={{ fontSize: "9px" }}>[iQuoto-live]</small>
                    </span>

                    <span className="ml-2 mr-5" style={{ minWidth: "45px" }}>
                      <div className="cardCaptionTitle">LEVERAGE</div>
                      {`1:${account[1].leverage}`}
                    </span>
                  </>
                ) : null}

                <span className="ml-2 mr-5" style={{ minWidth: "125px" }}>
                  <div className="cardCaptionTitle">BALANCE</div>
                  <span>
                    {account[1].balance
                      ? parseFloat(account[1].balance).toFixed(2)
                      : 0}{" "}
                  </span>
                  <span className="cardFlag cardCurrencyFlag float-right">
                    {account[1].baseCurrency}
                  </span>
                </span>

                {account[1].balance > 0 ? (
                  <Button
                    className="cardButton ml-auto px-0"
                    style={{
                      backgroundColor: "var(--color-dark-blue1)",
                    }}
                    onClick={() => props.selectAccount(account[1])}
                  >
                    SELECT
                  </Button>
                ) : (
                  <Button
                    className="cardButton ml-auto px-0"
                    as={Link}
                    to={`/deposit-funds/account/${account[1].number}`}
                  >
                    FUND NOW
                  </Button>
                )}
              </li>
            ))}
          </ul>
        ) : null}
      </Col>

      {showModal ? (
        <NoFundedAccounts handleModalClose={handleModalClose} />
      ) : null}
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Step);
