import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { editUserWeb } from "../../../../redux/actions/authActions";
import riskDisclosure from "../../../../assets/documents/Risk_Disclosure_v1.1.pdf";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!!localStorage.userData) {
      setUserData(prevState => {
        return {
          ...prevState,
          ...JSON.parse(localStorage.userData),
        };
      });
    }
  }, []);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 5) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(1);
      }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    const acceptRiskDisclosure = document.getElementById(
      "acceptRiskDisclosure"
    ).checked;

    if (
      acceptRiskDisclosure ||
      (!acceptRiskDisclosure &&
        window.confirm(
          "I confirm that I have the read iQuoto Internationals Risk Disclosure and understand the risks associated with leveraged Forex and CFDs trading."
        ))
    ) {
      const editUser = {
        step: 5,
        subStep: 1,
        userId: props.auth.user.id,
        acceptRiskDisclosure: true,
      };
      setDisableButton(true);
      props.editUserWeb(editUser);
    }
  };

  return (
    <>
      {isMobileOnly ? (
        <>
          <h1 className={classes.h1}>Legal</h1>
          <h2 className={classes.h2}>Risk disclosure</h2>
        </>
      ) : (
        <h1 className={classes.h1}>Risk disclosure</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Label className={classes.label}>Risks</Form.Label>
              <Form.Text className="pb-3">
                You should only consider trading Forex and/or CFDs if the
                statements below are true.
              </Form.Text>
            </Form.Row>

            <Form.Row>
              <Form.Text className="pb-3">
                I understand that, when trading leveraged products I risk losing
                all of my invested capital. It is my responsibility to monitor
                my positions and to manage the risks of trading. I will not own,
                or have any rights in, the underlying assets.
              </Form.Text>
            </Form.Row>

            <Form.Row>
              <Form.Text className="pb-3">
                I understand that market liquidity and volatility may impact
                pricing, including iQuoto Internationals ability to generate
                prices or execute orders.
              </Form.Text>
            </Form.Row>

            <Form.Row>
              <Form.Group
                as={Col}
                className="mt-4"
                style={{ maxWidth: "28px" }}
              >
                <Form.Check
                  custom
                  inline
                  label=""
                  type="checkbox"
                  id="acceptRiskDisclosure"
                  checked={userData.acceptRiskDisclosure}
                />
              </Form.Group>
              <Form.Group as={Col} className="mt-4">
                <label
                  className={classes.smallLabel}
                  htmlFor="acceptRiskDisclosure"
                >
                  I confirm that I have the read iQuoto Internationals{" "}
                  <Button
                    variant="link"
                    className="orangeLink"
                    href={riskDisclosure}
                    target="_blank"
                  >
                    Risk Disclosure
                  </Button>{" "}
                  and understand the risks associated with leveraged Forex and
                  CFDs trading.
                </label>
              </Form.Group>
            </Form.Row>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>
            <Button
              variant="link"
              className="simpleBackButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="blueButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  editUserWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { editUserWeb })(Step);
