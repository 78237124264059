import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { clearLocalStorage } from "../../../../utils/utils";

const Step = props => {
  useEffect(() => {
    clearLocalStorage();
  }, []);

  return (
    <>
      <h1 className={classes.h1}>Email already registered</h1>

      <Card className={classes.card}>
        <Card.Body>
          <div
            className={["text-center mt-4 mb-4", classes.regCompleteText].join(
              " "
            )}
          >
            Already have an account
          </div>

          <p className="text-center my-4 py-3">
            It looks like you already have registered for an account. Please
            <Button
              variant="link"
              onClick={() => (window.location.href = "/login")}
              className="orangeLink pl-1"
            >
              Login
            </Button>{" "}
            and you will be able to start your application where you left off!
          </p>

          <div className="d-flex justify-content-center">
            <Button
              onClick={() => (window.location.href = "/login")}
              className="blueButton mt-0 mb-4"
            >
              LOGIN
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Step);
