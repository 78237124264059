import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { updateEmploymentIncomeWeb } from "../../../../redux/actions/authActions";
import classes from "../RegisterNew.module.css";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!!localStorage.userData) {
      setUserData(prevState => {
        return {
          ...prevState,
          ...JSON.parse(localStorage.userData),
        };
      });
    }
  }, []);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 2) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(1);
      }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleChange = (e, target) => {
    let name;
    let value;

    if (target === "expectedDeposit") {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = target;
      value = e;
    }

    setUserData(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    setDisableButton(true);

    const financialInformation = {
      step: 2,
      subStep: 7,
      userId: props.auth.user.id,
      annualSalary: userData.annualSalary,
      savings: userData.savings,
      wealthSource: userData.wealthSource,
      expectedDeposit: userData.expectedDeposit,
    };

    props.updateEmploymentIncomeWeb(financialInformation);
  };

  return (
    <>
      {isMobileOnly ? (
        <>
          <h1 className={classes.h1}>Personal details</h1>
          <h2 className={classes.h2}>Financial information</h2>
        </>
      ) : (
        <h1 className={classes.h1}>Financial information</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col} className="mb-0">
                <Form.Label className={classes.label}>Annual income</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.annualSalary && userData.annualSalary === "<10,000"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("<10,000", "annualSalary")}
              >
                &lt;10,000
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.annualSalary &&
                  userData.annualSalary === "10,000 - 24,999"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("10,000 - 24,999", "annualSalary")}
              >
                10,000 - 24,999
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.annualSalary &&
                  userData.annualSalary === "25,000 - 50,000"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("25,000 - 50,000", "annualSalary")}
              >
                25,000 - 50,000
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.annualSalary &&
                  userData.annualSalary === "50,001 - 75,000"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("50,001 - 75,000", "annualSalary")}
              >
                50,001 - 75,000
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.annualSalary &&
                  userData.annualSalary === "75,001 - 100,000"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("75,001 - 100,000", "annualSalary")}
              >
                75,001 - 100,000
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.annualSalary && userData.annualSalary === "100,000 +"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("100,000 +", "annualSalary")}
              >
                100,000 +
              </Form.Group>
            </Form.Row>
            <Form.Text style={{ color: "red" }}>
              {errors.annualSalary}
            </Form.Text>

            <Form.Row>
              <Form.Group as={Col} className="mt-4 mb-0">
                <Form.Label className={classes.label}>
                  Value of savings and investments
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.savings && userData.savings === "<20,000"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("<20,000", "savings")}
              >
                &lt;20,000
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.savings && userData.savings === "20,000 - 50,000"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("20,000 - 50,000", "savings")}
              >
                20,000 - 50,000
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.savings && userData.savings === "50,001 - 100,000"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("50,001 - 100,000", "savings")}
              >
                50,001 - 100,000
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.savings && userData.savings === "100,001 - 150,000"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("100,001 - 150,000", "savings")}
              >
                100,001 - 150,000
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.savings && userData.savings === "150,001 - 200,000"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("150,001 - 200,000", "savings")}
              >
                150,001 - 200,000
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.savings && userData.savings === "200,000 +"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("200,000 +", "savings")}
              >
                200,000 +
              </Form.Group>
            </Form.Row>
            <Form.Text style={{ color: "red" }}>{errors.savings}</Form.Text>

            <Form.Row>
              <Form.Group as={Col} className="mt-4 mb-0">
                <Form.Label className={classes.label}>
                  Source of wealth
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.wealthSource &&
                  userData.wealthSource === "Employment"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("Employment", "wealthSource")}
              >
                Employment
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.wealthSource &&
                  userData.wealthSource === "Inheritance"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("Inheritance", "wealthSource")}
              >
                Inheritance
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.wealthSource && userData.wealthSource === "Savings"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("Savings", "wealthSource")}
              >
                Savings
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.wealthSource &&
                  userData.wealthSource === "Investment returns"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() =>
                  handleChange("Investment returns", "wealthSource")
                }
              >
                Investment returns
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.wealthSource && userData.wealthSource === "Other"
                    ? classes.choiceBoxActive
                    : "",
                ]}
                onClick={() => handleChange("Other", "wealthSource")}
              >
                Other
              </Form.Group>
              <Form.Group as={Col} />
            </Form.Row>
            <Form.Text style={{ color: "red" }}>
              {errors.wealthSource}
            </Form.Text>

            <Form.Row>
              <Form.Group as={Col} className="mt-4">
                <Form.Label className={classes.label}>
                  How much do you expect to deposit in next 12 months?
                </Form.Label>
                <Form.Control
                  type="number"
                  name="expectedDeposit"
                  required
                  onChange={e => handleChange(e, "expectedDeposit")}
                  defaultValue={userData.expectedDeposit}
                />
                <Form.Text style={{ color: "red" }}>
                  {errors.expectedDeposit}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>
            <Button
              variant="link"
              className="simpleBackButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="blueButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  updateEmploymentIncomeWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateEmploymentIncomeWeb })(Step);
