import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const Fund = props => {
  return (
    <Modal
      show={true}
      size="lg"
      onHide={() => props.handleModalClose("showAddEditAccountDetailsModal")}
    >
      <Modal.Header closeButton>
        <Modal.Title>No Funded Accounts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="wizardStepsParagraph px-0">
          Looks like you don’t have any Funded Trading Accounts. Click the Fund
          Now button below to Deposit Funds into a Trading Account. Once funded
          you will be able to transfer funds to other Trading Account you have
          with iQuoto Global.
        </p>

        <Button
          as={Link}
          to={`/deposit-funds/`}
          className="cardButton d-block"
          style={{
            backgroundColor: "var(--color-dark-blue1)",
          }}
        >
          FUND NOW
        </Button>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between"></Modal.Footer>
    </Modal>
  );
};

export default Fund;
