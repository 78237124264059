import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { updateTradingPreferencesWeb } from "../../../../redux/actions/authActions";
import classes from "../RegisterNew.module.css";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!!localStorage.userData) {
      setUserData(prevState => {
        return {
          ...prevState,
          ...JSON.parse(localStorage.userData),
        };
      });
    }
  }, []);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 4) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(1);
      }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleChange = (value, target) => {
    setUserData(prevState => {
      return {
        ...prevState,
        [target]: value,
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    setDisableButton(true);

    const tradingPreferences = {
      step: 4,
      subStep: 2,
      userId: props.auth.user.id,
      accountType: userData.accountType,
      leverage: userData.leverage,
      accountPlatform: "MT5",
    };

    props.updateTradingPreferencesWeb(tradingPreferences);
  };

  return (
    <>
      {isMobileOnly ? (
        <>
          <h1 className={classes.h1}>Account details</h1>
          <h2 className={classes.h2}>Account type</h2>
        </>
      ) : (
        <h1 className={classes.h1}>Account type</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col} className="mb-0">
                <Form.Label className={classes.label}>Account type:</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.accountType && userData.accountType === "Standard"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Standard", "accountType")}
              >
                Standard Account
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.accountType && userData.accountType === "ECN"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("ECN", "accountType")}
              >
                ECN Account
              </Form.Group>
              {/*{userData.baseAccountCurrency === "USD" &&*/}
              {/*userData.accountPlatform === "MT4" ? (*/}
              {/*  <Form.Group*/}
              {/*    as={Col}*/}
              {/*    className={[*/}
              {/*      classes.smallChoice,*/}
              {/*      userData.accountType && userData.accountType === "Swap Free"*/}
              {/*        ? classes.choiceBoxActive*/}
              {/*        : "",*/}
              {/*    ].join(" ")}*/}
              {/*    onClick={() => handleChange("Swap Free", "accountType")}*/}
              {/*  >*/}
              {/*    Swap Free Account*/}
              {/*  </Form.Group>*/}
              {/*) : null}*/}
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} className="mt-4 mb-0">
                <Form.Label className={classes.label}>Leverage:</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.leverage && userData.leverage === 50
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange(50, "leverage")}
              >
                1:50
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.leverage && userData.leverage === 100
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange(100, "leverage")}
              >
                1:100
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.leverage && userData.leverage === 200
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange(200, "leverage")}
              >
                1:200
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.leverage && userData.leverage === 300
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange(300, "leverage")}
              >
                1:300
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.leverage && userData.leverage === 400
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange(400, "leverage")}
              >
                1:400
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.leverage && userData.leverage === 500
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange(500, "leverage")}
              >
                1:500
              </Form.Group>
            </Form.Row>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>
            <Button
              variant="link"
              className="simpleBackButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="blueButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  updateTradingPreferencesWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateTradingPreferencesWeb })(Step);
