import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { loginUser } from "../../../redux/actions/authActions";
import { encryptData } from "../../../utils/utils";
import { Col, Form, Button, Spinner, Card } from "react-bootstrap";
import classes from "./Login.module.css";
import { FiAlertTriangle } from "react-icons/fi";
import logo from "../../../assets/images/iQuoto-Global-Logo-Blue.svg";
import { isMobileOnly, deviceType, browserName } from "react-device-detect";

// SOCIAL LOGIN DEPENDENCIES
// import io from "socket.io-client";
// import OAuth from "../SocialLogin/OAuth";
// import { API_URL } from "../../../config/config";
// const socket = io(API_URL);
// const providers = ["twitter", "google", "facebook"];
// SOCIAL LOGIN DEPENDENCIES END

class Login extends Component {
  state = {
    email: "",
    password: "",
    // socialId: "",
    errors: {},
    disableLoginBtn: false,
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated && this.props.auth.user.registered) {
      if (this.props.auth.user.eKYCApproved) {
        window.location.href = "/trading-accounts";
      } else {
        window.location.href = "/upload-documents";
      }
    }
  }

  static getDerivedStateFromProps(newProps) {
    if (newProps.auth.isAuthenticated && newProps.auth.user.registered) {
      if (newProps.auth.user.eKYCApproved) {
        window.location.href = "/trading-accounts";
      } else {
        window.location.href = "/upload-documents";
      }
    }

    if (Object.keys(newProps.errors).length > 0) {
      return {
        errors: newProps.errors,
        disableLoginBtn: false,
      };
    }

    // No state update necessary
    return null;
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    this.setState({ errors: {} });

    if (form.checkValidity()) {
      this.setState({ disableLoginBtn: true });

      const userData = {
        email: this.state.email,
        password: encryptData(this.state.password),
        deviceType: deviceType === "browser" ? "desktop" : deviceType,
        browserName,
        // socialId: this.state.socialId
      };

      this.props.loginUser(userData, this.props.history);
    }
  };

  render() {
    const { errors, disableLoginBtn } = this.state;

    return !this.props.auth.isAuthenticated ? (
      <div
        className="h-100 d-flex align-items-center justify-content-center"
        style={
          !isMobileOnly ? { backgroundColor: "var(--color-light-gray5)" } : null
        }
      >
        <Card className={[classes.card, "px-md-5"].join(" ")}>
          <Card.Body>
            <object
              data={logo}
              width="135"
              height="51"
              className={classes.loginLogo}
              onClick={() => (window.location.href = "https://iquoto.global/")}
              aria-label="iQuoto.com"
            />

            <Form onSubmit={e => this.handleSubmit(e)}>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className={classes.label}>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>{errors.email}</Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className={classes.label}>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.password}
                    {errors.passwordincorrect}
                  </Form.Text>

                  <Button
                    variant="link"
                    as={Link}
                    to="/forgot-password"
                    className={classes.forgotPassword}
                  >
                    Forgot password?
                  </Button>
                </Form.Group>
              </Form.Row>

              <Form.Text className="text-danger text-left pb-4">
                {errors.server || errors.emailorpassword ? (
                  <FiAlertTriangle className={classes.warningIcon} />
                ) : null}
                {errors.server}
                {errors.emailorpassword}
              </Form.Text>

              <Button
                type="submit"
                className="blueButton mt-0 mb-4 w-100"
                // onClick={this.handleSubmit}
                disabled={disableLoginBtn}
              >
                {disableLoginBtn ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    SIGN IN
                  </>
                ) : (
                  "SIGN IN"
                )}
              </Button>
            </Form>

            <br />

            <div
              className={[
                "w-100 d-flex justify-content-center pt-2",
                classes.smallText,
              ].join(" ")}
            >
              Not registered yet?
              <Button
                variant="link"
                className="orangeLink ml-1"
                onClick={() => (window.location.href = "/register")}
              >
                Open a Live account.
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    ) : (
      <p className="p-5">Logging in...</p>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(withRouter(Login));
