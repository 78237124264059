import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Card, Button, Spinner } from "react-bootstrap";
import classes from "./InternalTransfer.module.css";
import { requestTransfer } from "../../../../redux/actions/userActions";
import { getIncreasedAmount, getReducedAmount } from "../../../../utils/utils";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [btnDisabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      props.setPaymentResult("SUCCESS");
      props.nextStep("success");
    }

    if (Object.keys(props.errors).length > 0) {
      props.setPaymentResult("FAILED");
      props.nextStep("error");
    }
  }, [props]);

  const handleSubmit = () => {
    setBtnDisabled(true);

    let transferData = {
      sourceAccountPlatform: props.selectedAccountFrom.platform,
      sourceAccount: props.selectedAccountFrom.number,
      destinationAccountPlatform: props.selectedAccountTo.platform,
      destinationAccount: props.selectedAccountTo.number,
      transferCurrency: props.selectedAccountFrom.baseCurrency,
      transferAmount: props.transferAmount,
    };
    props.requestTransfer(transferData);
  };

  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders justify-content-between">
      <Col md="6">
        <div className="wizardStepsParagraph">
          Please confirm your internal transfer request
        </div>

        <div className="wizardStepsParagraph">
          Please check the payment details to make sure they are correct. If you
          are happy then click the 'SUBMIT' button to action your internal
          transfer.
        </div>

        <div style={!isMobileOnly ? { paddingLeft: "100px" } : {}}>
          <div className="wizardFundAmount">
            <Button
              className="cardButton float-left mt-3 ml-1"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              onClick={() => props.nextStep("select_amount")}
            >
              BACK
            </Button>
            <Button
              className="cardButton float-right mt-3"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              disabled={btnDisabled}
              onClick={handleSubmit}
            >
              {btnDisabled ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  SUBMIT
                </>
              ) : (
                "SUBMIT"
              )}
            </Button>
          </div>
        </div>
      </Col>
      <Col md="6">
        <Card
          className={[
            "customCardsStyle customInternalTransferCardsStyle",
            classes.InternalTransferCardPreview,
          ].join(" ")}
        >
          <Card.Body>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle pb-1">TRANSFER FROM</div>
              {props.selectedAccountFrom.number}
            </div>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle">
                BALANCE AVAILABLE TO TRANSFER
              </div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.selectedAccountFrom.balance).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountFrom.baseCurrency}
              </span>
            </div>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle">TRANSFER AMOUNT</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.transferAmount).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountFrom.baseCurrency}
              </span>
            </div>
            <div>
              <div className="cardCaptionTitle">BALANCE AFTER TRANSFER</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {getReducedAmount(
                  props.selectedAccountFrom.balance,
                  props.transferAmount.toString()
                )}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountFrom.baseCurrency}
              </span>
            </div>
          </Card.Body>
        </Card>

        <Card
          className={[
            "customCardsStyle customInternalTransferCardsStyle",
            classes.InternalTransferCardPreview,
          ].join(" ")}
        >
          <Card.Body>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle pb-1">TRANSFER TO</div>
              {props.selectedAccountTo.number}
            </div>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle">BALANCE</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.selectedAccountTo.balance).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                GBP
              </span>
            </div>
            <div style={{ height: "55px" }}>
              <div className="cardCaptionTitle">TRANSFER AMOUNT</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.transferAmount).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountTo.baseCurrency}
              </span>
            </div>
            <div>
              <div className="cardCaptionTitle">BALANCE AFTER TRANSFER</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {getIncreasedAmount(
                  props.selectedAccountTo.balance,
                  props.transferAmount.toString()
                )}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccountTo.baseCurrency}
              </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  requestTransfer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { requestTransfer })(Step);
