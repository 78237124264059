import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Card } from "react-bootstrap";
import classes from "./Withdraw.module.css";
import {
  allowedPaymentMethodsPerEmail,
  withdrawalMethodData,
} from "../../../../config/config";
import { isMobileOnly } from "react-device-detect";
import { africanAndSEACountries } from "../../../../config/constants";

const Step = ({
  auth,
  selectedAccount,
  successfulDeposits,
  selectWithdrawalMethod,
}) => {
  const isMethodAllowed = method => {
    if (
      method.id === "mpesa" &&
      (!africanAndSEACountries.includes(auth.user.country) ||
        selectedAccount.baseCurrency !== "USD" ||
        !allowedPaymentMethodsPerEmail[method.id].includes(auth.user.email))
    )
      return false;

    if (method.id === "credit_card" && !successfulDeposits.creditCard)
      return false;

    if (method.id === "crypto" && !successfulDeposits.crypto) return false;

    return true;
  };

  return (
    <Row className="mx-1 mx-md-4 greyBg bottomBorders">
      <Col className="py-5">
        <div className="wizardStepsParagraph">
          Please select your withdrawal method:
        </div>

        <div style={!isMobileOnly ? { paddingLeft: "80px" } : {}}>
          {withdrawalMethodData.filter(isMethodAllowed).map(method => (
            <div
              key={method.id}
              className="cardWithLeftBorder m-1"
              style={{ cursor: "pointer" }}
              onClick={() => selectWithdrawalMethod(method)}
            >
              <div className="cardBorder red" />
              <Card
                className={[
                  "customCardsStyle customFundingMethodCardsStyle",
                  classes.PaymentMethodCard,
                ].join(" ")}
              >
                <Card.Body>
                  <div style={{ height: "60px" }}>
                    <div className="float-left">
                      <div className="cardCaptionTitle">WITHDRAWAL METHOD</div>
                      {method.method}
                    </div>
                    <div className="float-right">
                      <img src={method.image} alt={method.method} />
                    </div>
                  </div>
                  <div style={{ height: "60px" }}>
                    <div className="float-left">
                      <div className="cardCaptionTitle">WITHDRAWAL TIME</div>
                      {method.time}
                    </div>
                    <div className="float-left pl-3">
                      <div className="cardCaptionTitle">FEES</div>
                      {method.fees}
                    </div>
                  </div>
                  <div className="cardCaptionParagraph">{method.text}</div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Step);
