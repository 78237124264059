import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SAFECHARGE } from "../../../../config/config";
import { getChecksum } from "../../../../utils/utils";
import { getUser } from "../../../../redux/actions/authActions";
import { requestApmTransfer } from "../../../../redux/actions/userActions";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  useEffect(() => {
    let depositData = {
      accountPlatform: props.selectedAccount.platform,
      accountNumber: props.selectedAccount.number,
      depositCurrency: props.selectedAccount.baseCurrency,
      depositAmountSent: props.depositAmount,
      paymentGatewayService: props.paymentMethod.paymentGateway,
      depositType: props.paymentMethod.id,
    };
    props.requestApmTransfer(depositData);
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      getUser(props.auth.user.email)
        .then(res => {
          const timeStamp = moment().format("YYYY-MM-DD.HH:mm:ss");

          window.location.href = `${
            SAFECHARGE.paymentUrl
          }/purchase.do?merchant_id=${SAFECHARGE.merchantId}&merchant_site_id=${
            SAFECHARGE.merchantSiteId
          }&currency=${
            props.selectedAccount.baseCurrency
          }&item_name_1=Deposit&item_number_1=Deposit&item_amount_1=${
            props.depositAmount
          }&item_quantity_1=1&total_amount=${props.depositAmount}&userid=${
            props.success.transferId
          }&user_token_id=${props.auth.user.id}&first_name=${encodeURIComponent(
            res.data.user.name
          )}&last_name=${encodeURIComponent(
            res.data.user.surname
          )}&email=${encodeURIComponent(
            res.data.user.email
          )}&address1=${encodeURIComponent(
            `${res.data.user.address} ${res.data.user.addressNo}`
          )}&city=${encodeURIComponent(
            res.data.user.city
          )}&zip=${encodeURIComponent(res.data.user.postcode)}&country=${
            res.data.user.country
          }&phone1=${encodeURIComponent(
            res.data.user.mobilePhone
          )}&version=4.0.0&payment_method=${
            props.paymentMethod.codeName
          }&payment_method_mode=filter&theme_id=${
            isMobileOnly ? "313836" : "313676"
          }&encoding=UTF-8&time_stamp=${timeStamp}&checksum=${getChecksum(
            SAFECHARGE.secretKey,
            SAFECHARGE.merchantId,
            SAFECHARGE.merchantSiteId,
            props.selectedAccount.baseCurrency,
            "Deposit",
            "Deposit",
            props.depositAmount,
            "1",
            props.depositAmount,
            props.success.transferId,
            props.auth.user.id,
            res.data.user.name,
            res.data.user.surname,
            res.data.user.email,
            `${res.data.user.address} ${res.data.user.addressNo}`,
            res.data.user.city,
            res.data.user.postcode,
            res.data.user.country,
            res.data.user.mobilePhone,
            "4.0.0",
            props.paymentMethod.codeName,
            "filter",
            isMobileOnly ? "313836" : "313676",
            "UTF-8",
            timeStamp
          )}
        `;
        })
        .catch(err => console.log(err));
    }

    if (Object.keys(props.errors).length > 0) {
      props.setPaymentResult("FAILED");
      props.nextStep("error");
    }
  }, [props]);

  return (
    <Row className="mx-1 mx-md-4 greyBg bottomBorders">
      <Col className="py-5">
        <div className="wizardStepsParagraph">Please wait...</div>
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  requestApmTransfer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { requestApmTransfer })(Step);
