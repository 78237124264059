import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Card, Button, Spinner } from "react-bootstrap";
import classes from "./Withdraw.module.css";
import { requestWithdrawal } from "../../../../redux/actions/userActions";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [btnDisabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      props.setPaymentResult("SUCCESS");
      props.nextStep("success");
    }

    if (Object.keys(props.errors).length > 0) {
      props.setPaymentResult("FAILED");
      props.nextStep("error");
    }
  }, [props]);

  const handleSubmit = () => {
    setBtnDisabled(true);

    let withdrawalData = {
      accountPlatform: props.selectedAccount.platform,
      accountNumber: props.selectedAccount.number,
      paymentGatewayService: props.paymentMethod.paymentGateway,
      withdrawalType: props.paymentMethod.id,
      withdrawalPaymentName: props.paymentMethod.method,
      withdrawalCurrency: props.selectedAccount.baseCurrency,
      withdrawalAmount: props.withdrawAmount,
      bankId: props.selectedBankDetails._id,
    };
    props.requestWithdrawal(withdrawalData);
  };

  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders justify-content-between">
      <Col md="7">
        <div className="wizardStepsParagraph">
          Please confirm your withdrawal request
        </div>

        <div className="wizardStepsParagraph">
          Please check the payment details to make sure they are correct. If you
          are happy then click the 'SUBMIT' button to action your withdrawal.
        </div>

        <div style={!isMobileOnly ? { paddingLeft: "100px" } : {}}>
          <div className="wizardFundAmount">
            <Button
              className="cardButton float-left mt-3 ml-1"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              onClick={() => props.nextStep("wire")}
            >
              BACK
            </Button>
            <Button
              className="cardButton float-right mt-3"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              disabled={btnDisabled}
              onClick={handleSubmit}
            >
              {btnDisabled ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  SUBMIT
                </>
              ) : (
                "SUBMIT"
              )}
            </Button>
          </div>
        </div>
      </Col>
      <Col md="4">
        <Card
          className={[
            "customCardsStyle customCardsStyleMediumFont",
            classes.PaymentMethodCardPreview,
          ].join(" ")}
        >
          <Card.Body>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">ACCOUNT NUMBER</div>
              {props.selectedAccount.number}
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">WITHDRAWAL METHOD</div>
              {props.paymentMethod.method}
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">
                BALANCE AVAILABLE TO WITHDRAW
              </div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.selectedAccount.balance).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">WITHDRAWAL AMOUNT</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.withdrawAmount).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
            <div>
              <div className="cardCaptionTitle">WITHDRAWAL FEE</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {props.paymentMethod.fees}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  requestWithdrawal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { requestWithdrawal })(Step);
