import React from "react";
import { Row, Col, Button } from "react-bootstrap";

const error = props => {
  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders wizardComplete">
      <Col>
        <div className="circle error">X</div>

        {props.children}

        <Button
          className="completeButton error"
          onClick={() => (window.location.href = props.link)}
        >
          TRY AGAIN
        </Button>
      </Col>
    </Row>
  );
};

export default error;
