import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { getBankDetails } from "../../../redux/actions/userActions";
import BankDetailsModal from "../../../components/Modals/BankDetails/BankDetails";
import BankDetailsAfricaSEALocalModal from "../../../components/Modals/BankDetails/BankDetailsAfricaSEALocal";
import classes from "./BankDetails.module.css";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import {
  africanSEAZotapayCountries,
  SEAZotapayCountries,
} from "../../../config/constants";

const BankDetails = props => {
  const [state, setState] = useState({
    addBankDetailsErrors: "",
    showAddEditIntlBankDetailsModal: false,
    showAddEditLocalBankDetailsModal: false,
    selectedBankDetails: {},
    loading: true,
  });
  const [bankDetails, setBankDetails] = useState([]);

  const isAfricanSEA = africanSEAZotapayCountries.includes(
    props.auth.user.country
  );
  const isSEA = SEAZotapayCountries.includes(props.auth.user.country);

  useEffect(() => {
    getBankDetails("all", "all")
      .then(res => {
        setBankDetails(res.data);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      // window.location.reload();
      createNotification("success");
      setState(prevState => {
        return {
          ...prevState,
          showAddEditIntlBankDetailsModal: false,
          showAddEditLocalBankDetailsModal: false,
          loading: true,
        };
      });
      getBankDetails("all", "all")
        .then(res => {
          setBankDetails(res.data);
          setState(prevState => {
            return {
              ...prevState,
              loading: false,
            };
          });
        })
        .catch(err => console.log(err));
    }

    if (
      Object.keys(props.errors).length > 0 &&
      props.errors.bankDetails !== undefined
    ) {
      setState(prevState => {
        return {
          ...prevState,
          addBankDetailsErrors: props.errors.bankDetails,
        };
      });
    }
  }, [props]);

  const handleModalShow = (target, selectedBankDetails) => {
    if (
      target === "showAddEditIntlBankDetailsModal" ||
      target === "showAddEditLocalBankDetailsModal"
    ) {
      setState(prevState => {
        return { ...prevState, selectedBankDetails };
      });
    }

    setState(prevState => {
      return { ...prevState, [target]: true };
    });
  };

  const handleModalClose = target => {
    setState(prevState => {
      return { ...prevState, [target]: false };
    });
  };

  const createNotification = type => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully added Bank Details. Please wait for admin to approve it.",
          "New Bank Details"
        );
        break;
      default:
    }
  };

  return (
    <>
      <Container className="mb-5" fluid>
        <Row>
          <Col>
            <h1 className="mx-md-4">
              Bank Details
              <Button
                type="button"
                className="blueButton float-right"
                style={{ fontWeight: "normal", marginTop: 0 }}
                onClick={() =>
                  handleModalShow("showAddEditIntlBankDetailsModal", "")
                }
              >
                Add Int'l Bank
              </Button>
              {!isSEA && isAfricanSEA ? (
                <Button
                  type="button"
                  className="blueButton float-right w-auto mr-3"
                  style={{ fontWeight: "normal", marginTop: 0 }}
                  onClick={() =>
                    handleModalShow("showAddEditLocalBankDetailsModal", "")
                  }
                >
                  Add Local Bank
                </Button>
              ) : null}
            </h1>
          </Col>
        </Row>
        <Row className="mx-md-4 py-3 greyBg topBorders bottomBorders">
          <Col xs="12">
            <p className={classes.smallerText}>
              Providing us with an Approved Bank Account enables us to send
              money to you quickly and easily.
            </p>
          </Col>

          <Col xs="12">
            <p className={classes.biggerText}>Approved Bank Accounts</p>
          </Col>

          <Col xs="12">
            {bankDetails.bankDetails && bankDetails.bankDetails.length > 0
              ? bankDetails.bankDetails.map(bankDetails =>
                  bankDetails.status === "approved" ? (
                    <div
                      key={bankDetails._id}
                      className="cardWithLeftBorder m-1"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleModalShow(
                          bankDetails.africaSEALocalBank
                            ? "showAddEditLocalBankDetailsModal"
                            : "showAddEditIntlBankDetailsModal",
                          bankDetails
                        )
                      }
                    >
                      <div className="cardBorder green" />
                      <Card
                        className={[
                          "customCardsStyle customFundingMethodCardsStyle",
                          classes.BankDetailsCardPreview,
                        ].join(" ")}
                      >
                        <Card.Body className="pl-3">
                          {bankDetails.bankCountry === "GB" ? (
                            <div style={{ height: "60px" }}>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  ACCOUNT NUMBER
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankAccountNumber}
                                </span>
                              </div>
                              <div className="float-left pl-2">
                                <div className="cardCaptionTitle fontWeightBold500">
                                  SORT CODE
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankSortCode}
                                </span>
                              </div>
                            </div>
                          ) : isAfricanSEA && bankDetails.bankCode ? (
                            <div style={{ height: "60px" }}>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  ACCOUNT NUMBER
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankAccountNumber}
                                </span>
                              </div>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  ACCOUNT NAME
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankAccountName}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div style={{ height: "60px" }}>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  IBAN
                                </div>
                                <span
                                  style={{
                                    display: "block",
                                    marginTop: "4px",
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  {bankDetails.bankIBAN}
                                </span>
                              </div>
                              <div className="float-left pl-2">
                                <div className="cardCaptionTitle fontWeightBold500">
                                  SWIFT/BIC
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankSwift}
                                </span>
                              </div>
                            </div>
                          )}

                          <div>
                            <div
                              className="float-left"
                              style={{ width: "135px" }}
                            >
                              <div className="cardCaptionTitle fontWeightBold500">
                                DATE ADDED
                              </div>
                              <span style={{ fontSize: "0.9375rem" }}>
                                {moment(bankDetails.timestamp * 1000).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </div>
                            <div
                              className="float-left"
                              style={{ maxWidth: "145px" }}
                            >
                              <div className="cardCaptionTitle fontWeightBold500">
                                BANK NAME
                              </div>
                              <span
                                style={{
                                  display: "block",
                                  fontSize: "0.9375rem",
                                }}
                              >
                                {bankDetails.bankName}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ) : null
                )
              : null}
          </Col>

          <Col xs="12" className="mt-5">
            <p className={classes.biggerText}>Pending Bank Accounts</p>
          </Col>

          <Col xs="12">
            {bankDetails.bankDetails && bankDetails.bankDetails.length > 0
              ? bankDetails.bankDetails.map(bankDetails =>
                  bankDetails.status === "pending" ? (
                    <div
                      key={bankDetails._id}
                      className="cardWithLeftBorder m-1"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleModalShow(
                          bankDetails.africaSEALocalBank
                            ? "showAddEditLocalBankDetailsModal"
                            : "showAddEditIntlBankDetailsModal",
                          bankDetails
                        )
                      }
                    >
                      <div className="cardBorder orange" />
                      <Card
                        className={[
                          "customCardsStyle customFundingMethodCardsStyle",
                          classes.BankDetailsCardPreview,
                        ].join(" ")}
                      >
                        <Card.Body className="pl-3">
                          {bankDetails.bankCountry === "GB" ? (
                            <div style={{ height: "60px" }}>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  ACCOUNT NUMBER
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankAccountNumber}
                                </span>
                              </div>
                              <div className="float-left pl-2">
                                <div className="cardCaptionTitle fontWeightBold500">
                                  SORT CODE
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankSortCode}
                                </span>
                              </div>
                            </div>
                          ) : isAfricanSEA && bankDetails.bankCode ? (
                            <div style={{ height: "60px" }}>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  ACCOUNT NUMBER
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankAccountNumber}
                                </span>
                              </div>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  ACCOUNT NAME
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankAccountName}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div style={{ height: "60px" }}>
                              <div
                                className="float-left"
                                style={{ width: "135px" }}
                              >
                                <div className="cardCaptionTitle fontWeightBold500">
                                  IBAN
                                </div>
                                <span
                                  style={{
                                    display: "block",
                                    marginTop: "4px",
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  {bankDetails.bankIBAN}
                                </span>
                              </div>
                              <div className="float-left pl-2">
                                <div className="cardCaptionTitle fontWeightBold500">
                                  SWIFT/BIC
                                </div>
                                <span style={{ fontSize: "0.9375rem" }}>
                                  {bankDetails.bankSwift}
                                </span>
                              </div>
                            </div>
                          )}

                          <div>
                            <div
                              className="float-left"
                              style={{ width: "135px" }}
                            >
                              <div className="cardCaptionTitle fontWeightBold500">
                                DATE ADDED
                              </div>
                              <span style={{ fontSize: "0.9375rem" }}>
                                {moment(bankDetails.timestamp * 1000).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </div>
                            <div
                              className="float-left"
                              style={{ maxWidth: "145px" }}
                            >
                              <div className="cardCaptionTitle fontWeightBold500">
                                BANK NAME
                              </div>
                              <span
                                style={{
                                  display: "block",
                                  fontSize: "0.9375rem",
                                }}
                              >
                                {bankDetails.bankName}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ) : null
                )
              : null}
          </Col>
        </Row>
      </Container>

      {state.showAddEditIntlBankDetailsModal ? (
        <BankDetailsModal
          handleModalClose={handleModalClose}
          bankDetails={state.selectedBankDetails}
        />
      ) : null}

      {isAfricanSEA && state.showAddEditLocalBankDetailsModal ? (
        <BankDetailsAfricaSEALocalModal
          handleModalClose={handleModalClose}
          bankDetails={state.selectedBankDetails}
        />
      ) : null}
    </>
  );
};

BankDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(BankDetails);
