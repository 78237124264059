import fundingMethodPayAttitude from "../assets/images/funding-methods/fundingMethodPayAttitude.gif";
import fundingMethod1Voucher from "../assets/images/funding-methods/fundingMethod1Voucher.gif";
import fundingMethodAirtel from "../assets/images/funding-methods/fundingMethodAirtelMoney.gif";
import fundingMethodMPesa from "../assets/images/funding-methods/fundingMethodMPesa.gif";
import fundingMethodMTN from "../assets/images/funding-methods/fundingMethodMTN.gif";
import fundingMethodTigo from "../assets/images/funding-methods/fundingMethodTigo.gif";
import fundingMethodVodafone from "../assets/images/funding-methods/fundingMethodVodafone.gif";
import fundingMethodBarter from "../assets/images/funding-methods/fundingMethodBarter.gif";
import fundingMethodPaga from "../assets/images/funding-methods/fundingMethodPaga.gif";
import fundingMethodVisaQR from "../assets/images/funding-methods/fundingMethodVisaQR.gif";
import fundingMethodUSSD from "../assets/images/funding-methods/fundingMethodUSSD.gif";
import fundingMethodBank from "../assets/images/funding-methods/fundingMethodBank.gif";
// import fundingMethodOrangeMoney from "../assets/images/funding-methods/fundingMethodOrangeMoney.gif";

import fundingMethodOnlineBank from "../assets/images/funding-methods/fundingMethodOnlineBank.gif";
import fundingMethodOfflineBank from "../assets/images/funding-methods/fundingMethodOfflineBank.gif";
import fundingMethodATMOnline from "../assets/images/funding-methods/fundingMethodATMOnline.gif";
import fundingMethodATMOffline from "../assets/images/funding-methods/fundingMethodATMOffline.gif";
import fundingMethodEWalletMoMo from "../assets/images/funding-methods/fundingMethodEWalletMoMo.gif";
import fundingMethodEWalletZaloPay from "../assets/images/funding-methods/fundingMethodEWalletZaloPay.gif";
import fundingMethodEWalletNganLuong from "../assets/images/funding-methods/fundingMethodEWalletNganLuong.gif";
import fundingMethodQR from "../assets/images/funding-methods/fundingMethodQR.gif";

export const africanSEADepositMethodData = {
  // Africa
  payattitude: {
    id: "payattitude",
    paymentGateway: "ZP",
    method: "PayAttitude",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodPayAttitude,
    text: "Fund using PayAttitude",
  },
  "1voucher": {
    id: "1voucher",
    paymentGateway: "ZP",
    method: "1Voucher",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethod1Voucher,
    text: "Fund instantly with 1Voucher",
  },
  airtel: {
    id: "mobile_money",
    paymentGateway: "ZP",
    method: "Airtel Money",
    bankCode: "AIRTEL",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodAirtel,
  },
  mpesa: {
    id: "mobile_money",
    paymentGateway: "ZP",
    method: "M-Pesa",
    bankCode: "MPS",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodMPesa,
    text: "Fund instantly with your M-Pesa account",
  },
  mtn: {
    id: "mobile_money",
    paymentGateway: "ZP",
    method: "MTN",
    bankCode: "MTN",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodMTN,
    text: "Fund instantly with your MTN account",
  },
  tigo: {
    id: "mobile_money",
    paymentGateway: "ZP",
    method: "Tigo",
    bankCode: "TIGO",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodTigo,
    text: "Fund instantly with your Tigo account",
  },
  vodafone: {
    id: "mobile_money",
    paymentGateway: "ZP",
    method: "Vodafone",
    bankCode: "VODAFONE",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodVodafone,
    text: "Fund instantly with your Vodafone account",
  },
  barter: {
    id: "barter",
    paymentGateway: "ZP",
    method: "Barter",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodBarter,
    text: "Fund instantly with Barter",
  },
  paga: {
    id: "paga",
    paymentGateway: "ZP",
    method: "Paga",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodPaga,
    text: "Fund instantly with your Paga account",
  },
  visaqr: {
    id: "visaqr",
    paymentGateway: "ZP",
    method: "Visa QR",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodVisaQR,
    text: "Fund instantly with Visa QR",
  },
  ussd: {
    id: "ussd",
    paymentGateway: "ZP",
    method: "USSD",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodUSSD,
    text: "Fund instantly with USSD",
  },
  wire_zotapay: {
    id: "wire_zotapay",
    paymentGateway: "ZP",
    method: "Local Bank",
    type: "wire_zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodBank,
    text: "Fund using bank transfer to our secure segregated bank account",
  },

  // SEA
  online_bank: {
    id: "online_bank",
    paymentGateway: "ZP",
    method: "Online Bank",
    type: "online_bank",
    time: "Instant",
    fees: "0%",
    image: fundingMethodOnlineBank,
  },
  offline_bank: {
    id: "offline_bank",
    paymentGateway: "ZP",
    method: "Offline Bank",
    type: "offline_bank",
    time: "Instant",
    fees: "0%",
    image: fundingMethodOfflineBank,
  },
  atm_online: {
    id: "atm_online",
    paymentGateway: "ZP",
    method: "ATM Online",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodATMOnline,
  },
  atm_offline: {
    id: "atm_offline",
    paymentGateway: "ZP",
    method: "ATM Offline",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodATMOffline,
  },
  ewallet_momo: {
    id: "ewallet",
    paymentGateway: "ZP",
    method: "eWallet Momo",
    bankCode: "MMQR",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodEWalletMoMo,
  },
  ewallet_zalopay: {
    id: "ewallet",
    paymentGateway: "ZP",
    method: "eWallet ZaloPay",
    bankCode: "ZLQR",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodEWalletZaloPay,
  },
  ewallet_nganluong: {
    id: "ewallet",
    paymentGateway: "ZP",
    method: "eWallet NganLuong",
    bankCode: "NLW",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodEWalletNganLuong,
  },
  qr: {
    id: "qr",
    paymentGateway: "ZP",
    method: "QR",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodQR,
  },
};

export const africanSEAWithdrawalMethodData = {
  // Africa
  payattitude: {
    id: "payattitude",
    paymentGateway: "ZP",
    method: "PayAttitude",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodPayAttitude,
  },
  "1voucher": {
    id: "1voucher",
    paymentGateway: "ZP",
    method: "1Voucher",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethod1Voucher,
  },
  airtel: {
    id: "mobile_money",
    paymentGateway: "ZP",
    method: "Airtel Money",
    bankCode: "AIRTEL",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodAirtel,
  },
  mpesa: {
    id: "mobile_money",
    paymentGateway: "ZP",
    method: "M-Pesa",
    bankCode: "MPS",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodMPesa,
  },
  mtn: {
    id: "mobile_money",
    paymentGateway: "ZP",
    method: "MTN",
    bankCode: "MTN",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodMTN,
  },
  tigo: {
    id: "mobile_money",
    paymentGateway: "ZP",
    method: "Tigo",
    bankCode: "TIGO",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodTigo,
  },
  vodafone: {
    id: "mobile_money",
    paymentGateway: "ZP",
    method: "Vodafone",
    bankCode: "VODAFONE",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodVodafone,
  },
  barter: {
    id: "barter",
    paymentGateway: "ZP",
    method: "Barter",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodBarter,
  },
  paga: {
    id: "paga",
    paymentGateway: "ZP",
    method: "Paga",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodPaga,
  },
  visaqr: {
    id: "visaqr",
    paymentGateway: "ZP",
    method: "Visa QR",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodVisaQR,
  },
  ussd: {
    id: "ussd",
    paymentGateway: "ZP",
    method: "USSD",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodUSSD,
  },
  wire_zotapay: {
    id: "wire_zotapay",
    paymentGateway: "ZP",
    method: "Local Bank Transfer",
    type: "wire_zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodBank,
  },

  // SEA
  online_bank: {
    id: "online_bank",
    paymentGateway: "ZP",
    method: "Online Bank",
    type: "online_bank",
    time: "Instant",
    fees: "0%",
    image: fundingMethodOnlineBank,
  },
  offline_bank: {
    id: "offline_bank",
    paymentGateway: "ZP",
    method: "Offline Bank",
    type: "offline_bank",
    time: "Instant",
    fees: "0%",
    image: fundingMethodOfflineBank,
  },
  atm_online: {
    id: "atm_online",
    paymentGateway: "ZP",
    method: "ATM Online",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodATMOnline,
  },
  atm_offline: {
    id: "atm_offline",
    paymentGateway: "ZP",
    method: "ATM Offline",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodATMOffline,
  },
  ewallet_momo: {
    id: "ewallet",
    paymentGateway: "ZP",
    method: "eWallet Momo",
    bankCode: "MMQR",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodEWalletMoMo,
  },
  ewallet_zalopay: {
    id: "ewallet",
    paymentGateway: "ZP",
    method: "eWallet ZaloPay",
    bankCode: "ZLQR",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodEWalletZaloPay,
  },
  ewallet_nganluong: {
    id: "ewallet",
    paymentGateway: "ZP",
    method: "eWallet NganLuong",
    bankCode: "NLW",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodEWalletNganLuong,
  },
  qr: {
    id: "qr",
    paymentGateway: "ZP",
    method: "QR",
    type: "zotapay",
    time: "Instant",
    fees: "0%",
    image: fundingMethodQR,
  },
};

export const availableDepositMethodsPerCountry = {
  // Africa
  ZA: [/*"1voucher",*/ "wire_zotapay"],
  UG: ["airtel", "mpesa", "mtn", "tigo", "vodafone" /*, "barter"*/],
  NG: [
    "payattitude",
    /*"1voucher",*/
    "visaqr",
    "ussd",
    "barter",
    "paga",
    "wire_zotapay",
  ],
  BI: ["airtel", "mpesa", "mtn", "tigo", "vodafone"],
  KE: ["mpesa"],
  CF: ["airtel", "mpesa", "mtn", "tigo", "vodafone"],
  /*
  TZ: ["barter"],
  RW: ["airtel", "mpesa", "mtn", "tigo", "vodafone", "barter"],
  GH: ["airtel", "mpesa", "mtn", "tigo", "vodafone", "barter"],
  ZM: ["airtel", "mpesa", "mtn", "tigo", "vodafone", "barter"],
  CG: ["barter"],
  CD: ["barter"],
  CM: ["airtel", "mpesa", "mtn", "tigo", "vodafone", "barter"],
   */

  // SEA
  VN: [
    "online_bank",
    "offline_bank",
    "ewallet_momo",
    "ewallet_zalopay",
    "ewallet_nganluong",
    "atm_online",
    "atm_offline",
  ],
  TH: ["online_bank", "offline_bank", "qr"],
  ID: ["online_bank", "offline_bank"],
  MY: ["online_bank"],
};

export const availableWithdrawalMethodsPerCountry = {
  // Africa
  ZA: ["wire_zotapay"],
  UG: ["airtel", "mpesa", "mtn", "tigo", "vodafone" /*, "barter"*/],
  NG: [
    "payattitude",
    /*"1voucher",*/ "visaqr",
    "ussd",
    "barter",
    "paga",
    "wire_zotapay",
  ],
  BI: ["airtel", "mpesa", "mtn", "tigo", "vodafone"],
  KE: ["mpesa"],
  CF: ["airtel", "mpesa", "mtn", "tigo", "vodafone"],
  /*
  TZ: ["wire_zotapay"],
  RW: [],
  GH: ["airtel", "mpesa", "mtn", "tigo", "vodafone"],
  ZM: ["airtel", "mpesa", "mtn", "tigo", "vodafone"],
  CG: ["barter"],
  CD: ["barter"],
  CM: ["airtel", "mpesa", "mtn", "tigo", "vodafone", "orange_money"],
   */

  // SEA
  VN: [
    "online_bank",
    "offline_bank",
    "ewallet_momo",
    "ewallet_zalopay",
    "ewallet_nganluong",
    "atm_online",
    "atm_offline",
  ],
  TH: ["online_bank", "offline_bank", "qr"],
  ID: ["online_bank", "offline_bank"],
  MY: ["online_bank"],
};

export const availableDepositBanksPerCountry = {
  // Africa
  ZA: {
    ABSA: "ABSA",
    AFB: "African Bank",
    BIDVEST: "Bidvest Bank Geow",
    CAPITEC: "Capitec Bank",
    FNB: "FNB",
    INVESTEC: "Investec",
    NEDBANK: "Nedbank",
    STANDARD: "Standard Bank",
    TYME: "TymeBank",
  },
  NG: {
    ACC: "Access Bank Nigeria",
    NG23: "Citi Bank",
    DMN: "Diamond Bank Plc",
    NG50: "EcoBank",
    EWMF: "Eyowo Microfinance Bank",
    FDLT: "Fidelity Bank Plc",
    FTMF: "FINATRUST MICROFINANCE BANK",
    FST: "First Bank Plc",
    FCM: "First City Monument Bank Plc",
    NG58: "GTBank Plc",
    HRB: "Heritage Bank",
    HSMF: "Highstreet Microfinance Bank",
    JAIZ: "Jaiz Bank",
    KSTB: "Keystone Bank Plc",
    KMMF: "Kuda MF Bank",
    PLB: "Polaris bank",
    PVD: "Providus Bank",
    STBK: "Stanbic IBTC Bank Plc",
    SCTBN: "Standard Chartered Bank Nigeria Limited",
    STRL: "Sterling Bank Plc",
    SUT: "Suntrust Bank",
    UBN: "Union Bank of Nigeria Plc",
    UNBA: "United Bank for Africa Plc",
    UNT: "Unity Bank Plc",
    VFDMF: "VFD Micro Finance Bank",
    WEMA: "Wema Bank Plc",
    ZNTH: "Zenith Bank Plc",
  },
};

export const availableWithdrawalBanksPerCountry = {
  // Africa
  ZA: {
    ABSA: "ABSA",
    CAPITEC: "Capitec Bank",
    FNB: "First National Bank",
    INVESTEC: "Investec Bank",
    NEDBANK: "Nedbank",
    STANDARD: "Standard Bank",
  },
  NG: {
    ACC: "Access Bank Nigeria",
    NG23: "Citi Bank",
    DMN: "Diamond Bank Plc",
    NG50: "EcoBank",
    EWMF: "Eyowo Microfinance Bank",
    FDLT: "Fidelity Bank Plc",
    FTMF: "FINATRUST MICROFINANCE BANK",
    FST: "First Bank Plc",
    FCM: "First City Monument Bank Plc",
    NG58: "GTBank Plc",
    HRB: "Heritage Bank",
    HSMF: "Highstreet Microfinance Bank",
    JAIZ: "Jaiz Bank",
    KSTB: "Keystone Bank Plc",
    KMMF: "Kuda MF Bank",
    PLB: "Polaris bank",
    PVD: "Providus Bank",
    STBK: "Stanbic IBTC Bank Plc",
    SCTBN: "Standard Chartered Bank Nigeria Limited",
    STRL: "Sterling Bank Plc",
    SUT: "Suntrust Bank",
    UBN: "Union Bank of Nigeria Plc",
    UNBA: "United Bank for Africa Plc",
    UNT: "Unity Bank Plc",
    VFDMF: "VFD Micro Finance Bank",
    WEMA: "Wema Bank Plc",
    ZNTH: "Zenith Bank Plc",
  },
  GH: {
    GH280100: "Access Bank",
    GH080100: "AGRICULTURAL DEVELOPMENT BANK",
    GH210100: "AMALGAMATED BANK LTD",
    GH070100: "ARB APEX BANK LTD",
    GH260100: "BANK OF BARODA",
    GH010100: "Bank of Ghana",
    GH030100: "BARCLAYS BANK",
    GH270100: "BSIC GHANA LTD",
    GH140100: "Cal Bank",
    GH130100: "Ecobank",
    GH290100: "Energy Bank",
    GH200100: "FBN BANK GHANA",
    GH240100: "FIDELITY BANK",
    GH170100: "FIRST ATLANTIC MERCHANT",
    GH310100: "FIRST CAPITAL PLUS BANK",
    GH330100: "First National Bank",
    GH040100: "GCB Bank Ltd",
    GH320100: "GN BANK LTD",
    GH230100: "Guaranty Trust Bank",
    GH110100: "HFC Bank",
    GH250100: "INTERCONTINENTAL BANK LTD",
    GH050100: "National Investment Bank",
    GH360100: "OMNIBANK GHANA LTD",
    GH350100: "PREMIUM BANK",
    GH180100: "PRUDENTIAL BANK LTD",
    GH300100: "Royal Bank",
    GH090100: "SG-SSB LTD",
    GH340100: "SOVEREIGN BANK",
    GH190100: "Stanbic Bank",
    GH020100: "Standard Chartered Bank",
    GH150100: "THE TRUST BANK LTD",
    GH220100: "UNIBANK GHANA LTD",
    GH060100: "United Bank for Africa",
    GH100100: "Universal Merchant Bank",
    GH160100: "UT BANK",
    GH120100: "Zenith Bank",
  },
  BI: {
    econet_bi: "Econet Ecocash",
  },
  KE: {
    "03": "Absa Bank Kenya Plc",
    35: "African Banking Corp. Bank Ltd",
    19: "Bank of Africa Kenya Limited",
    31: "CFC Stanbic Bank Kenya Limited",
    30: "Chase Bank Limited",
    16: "Citibank N.A.",
    11: "Co-operative Bank of Kenya Limited",
    23: "Consolidated Bank of Kenya Limited",
    25: "Credit Bank Limited",
    63: "Diamond Trust Bank Limited",
    20: "Dubai Bank Kenya Limited",
    43: "EcoBank PLC",
    49: "Equatorial Commercial Bank Limited",
    68: "Equity Bank Limited",
    70: "Family Bank Ltd",
    74: "First Community Bank",
    53: "Guaranty Trust Bank",
    55: "Guardian Bank Limited",
    72: "Gulf African Bank Ltd",
    61: "Housing Finance Bank",
    39: "Imperial Bank Limited",
    57: "Investments & Mortgages Bank Limited",
    51: "Jamii Bora Bank",
    66: "K-Rep Bank Limited",
    "01": "Kenya Commercial Bank Limited",
    18: "Middle East Bank Kenya Limited",
    12: "National Bank of Kenya Limited",
    "07": "NCBA Bank",
    14: "Oriental Commercial Bank Limited",
    50: "Paramount Universal Bank Limited",
    10: "Prime Bank Limited",
    26: "Trans-National Bank Limited",
    76: "UBA Kenya Bank Ltd",
    54: "Victoria Commercial Bank Limited",
  },
  TZ: {
    "035": "Accessbank Tanzania Ltd",
    733: "African Banking Corporation Tanzania Ltd",
    "012": "Akiba Commercial Bank Plc",
    "046": "Amana Bank Limited",
    "031": "Azania Bank Limited",
    "033": "Bank M Tanzania Plc",
    "029": "Bank of Baroda Ltd",
    "036": "Bank of India Limited",
    "001": "Bank of Tanzania",
    "020": "Barclays Bank Tanzania Ltd",
    "009": "BOA Bank Tanzania",
    "058": "Canara Bank Ltd",
    "053": "China Commercial Bank Limited",
    "008": "Citibank Tanzania Ltd",
    "023": "Commercial Bank of Africa Ltd",
    "049": "Covenant Bank for Women Limited",
    "003": "CRDB Bank Limited",
    "011": "Diamond Trust Bank Tanzania Ltd",
    "040": "Ecobank Tanzania Limited",
    "047": "Equity Bank Tanzania Limited",
    "013": "Eximbank Ltd",
    "045": "First National Bank of Tanzania",
    "018": "Habib African Bank",
    "021": "I And M Bank (T) Limited",
    "019": "International Commercial Bank Limited",
    "017": "KCB Bank Tanzania Limited",
    "014": "Kilimanjaro Co-Operative Bank Ltd",
    "051": "Maendeleo Bank Ltd",
    "039": "Mkombozi Commercial Bank Ltd",
    "042": "Mwanga Community Bank Ltd",
    "026": "NIC Bank Limited",
    "016": "NMB Bank Plc",
    "006": "Stanbic Bank Tanzania Limited",
    "005": "Standard Chartered Bank Tanzania Ltd",
    "015": "The National Bank of Commerce",
    "004": "The People's Bank of Zanzibar",
    "050": "UBL Bank Limited",
    "032": "Uchumi Commercial Bank Ltd",
    "038": "United Bank for Africa Limited",
  },
  UG: {
    UG031000: "Abc Capital Bank Limited",
    UG31000: "Abc Capital Bank LTD",
    UG13000: "Bank of Africa",
    UG034000: "Bank of India",
    UG99000: "Bank of Uganda 1",
    UG47000: "Bank of Uganda",
    UG01000: "Barclays Bank of Uganda Limited",
    UG18000: "Cairo International Bank",
    UG16000: "CENTENARY Rural Development",
    UG22000: "Citibank N.A",
    UG36000: "Commercial Bank of Africa Uganda Limited",
    UG17000: "Crane Bank",
    UG05000: "DFCU Bank",
    UG19000: "Diamond Trust Bank",
    UG029000: "Ecobank (U) LTD",
    UG30000: "Equity Bank",
    UG32000: "Exim Bank",
    UG37000: "Finance Trust Bank",
    UG053000: "Finca Uganda Limited 1",
    UG53000: "Finca Uganda Limited",
    UG24000: "Global Trust Bank",
    UG27000: "Guarantee Trust Bank",
    UG23000: "Housing Finance Bank",
    UG25000: "KCB Bank Uganda LTD",
    UG15000: "National Bank of Commerce",
    UG35000: "Nc Bank Uganda Limited",
    UGNEDZAR: "Nedbank",
    UG61000: "Opportunity Bank Uganda Limited",
    UG11000: "Orient Bank",
    UG56000: "Post Bank Uganda Limited",
    UG57000: "Pride Microfinance Limited",
    UG04000: "Stanbic Bank Limited",
    UG08000: "Standard Chartered Bank",
    UG62000: "Top Finance Bank Limited",
    UG06000: "Tropical Bank OF Africa",
    UG26000: "United Bank for Africa UG LTD",
  },

  // SEA
  VN: {
    ABB: "An Binh Joint - stock Commercial Bank",
    ACB: "Asia Commercial Bank",
    AGB: "Agribank",
    ANZ: "ANZ bank",
    BAB: "Bac A Commercial Joint Stock Bank (Bac A Bank)",
    BIDV: "Bank for Investment and Development of Vietnam",
    BOCV: "Bank of China Vietnam",
    BVB: "Bao Viet Bank",
    CTB: "City Bank",
    DAB: "DongA Joint Stock Commercial Bank",
    DEUTVN: "Deutsche Bank",
    EXIM: "Eximbank Vietnam",
    GPB: "Global Petro Commercial Joint Stock Bank (GPBank)",
    HDB: "HD Bank",
    HLBBVN: "Hong Leong Bank Vietnam",
    HSBV: "HSBC Vietnam",
    IABBVN: "IndovinaBank",
    KLCB: "Kien Long Commercial Joint - Stock Bank",
    LVBKVN: "LienVietPostBank",
    MB: "Military Commercial Joint Stock Bank",
    MSB: "Maritime Bank",
    NAB: "Nam A commercial joint stock bank (NamABank)",
    NVB: "National Citizen Bank",
    OCB: "Orient Commercial Joint Stock Bank",
    OJB: "Ocean Commercial One Member Limited Liability Bank (OceanBank)",
    PBVN: "Public Bank Vietnam",
    PGB: "Petrolimex Group Commercial Joint Stock Bank (PGBank)",
    PNBKVN: "SouthernBank",
    PVCB: "PVcomBank",
    SACOM: "Sacom Bank",
    SCMB: "Saigon Commercial Bank",
    SCTBV: "Standard Chartered Vietnam Bank",
    SEA: "Southeast Asia Commercial Joint Stock Bank",
    SGB: "Saigon Bank",
    SHCB: "SHB Bank",
    SHNB: "Shinhan Bank",
    TCB: "Techcom Bank",
    TPB: "Tien Phong Bank",
    VAB: "Vietnam Asia Commercial Joint Stock Bank (VietA Bank)",
    VCB: "Vietcom Bank",
    VCCB: "Viet Capital Commercial Joint Stock Bank",
    VIB: "Vietnam International Bank",
    VIDPVN: "VID Public Bank",
    VPB: "VPBank",
    VTB: "VietinBank",
  },
  TH: {
    BBL: "Bangkok Bank",
    BOA: "Bank of Ayudhya",
    CIMBT: "CIMB Thai",
    CITIT: "Citibank",
    GHBT: "Government Housing Bank",
    GSB: "Government Savings Bank",
    HSBCTH: "Hong Kong Shanghai Bank",
    ICBC_TH: "Industrial and Commercial Bank of China (Thai)",
    KKR: "KasiKorn Bank",
    KNK: "Kiatnakin Bank",
    KTB: "KTB Net Bank",
    LHB: "Land and Houses Bank PCL",
    SCB: "Siam Commercial Bank",
    SCTBT: "Standard Chartered (Thai)",
    SITYTH: "Siam City Bank",
    TBK: "Thanachart Bank",
    TFPCTH: "Thai Investment and Securities Company Bank",
    TIBTTH: "Islamic Bank of Thailand",
    TMB: "TMB Bank Public Company Limited",
    UOBT: "United Overseas Bank (Thai)",
  },
  ID: {
    BTPN: "Bank BTPN",
    BBKP: "Bank Bukopin",
    BCW: "Bank Commonwealth",
    BDNM: "Bank Danamon",
    MDR: "Bank Mandiri",
    BMP: "Bank Maspion",
    BM: "Bank Mega",
    BMTK: "Bank Mestika",
    BPNN: "Bank Panin",
    PMTB: "Bank Permata",
    BSNM: "Bank Sinar Mas",
    BSMT: "Bank Sumut",
    BNI: "BNI Bank",
    BRI: "BRI Bank",
    CIMBN: "CIMB Niaga",
    //DANA: "Dana eWallet",
    HSBI: "HSBC Indonesia",
    BCA: "KlikBCA",
    MBBI: "Maybank/BII",
    OCBCI: "OCBC Indonesia",
    UOBI: "United Overseas Bank (Indonesia)",
  },
  MY: {
    AFF: "Affin Bank",
    ALB: "Alliance Bank",
    AMB: "AM Bank",
    BIMB: "Bank Islam Malaysia",
    BRM: "Bank Rakyat Malaysia",
    BSN: "Bank Simpanan National",
    CIMB: "CIMB Bank",
    CITI: "CITI bank",
    HLB: "Hong Leong Bank",
    HSBC: "HSBC Bank",
    MBB: "Maybank",
    OCBC: "OCBC Bank",
    PBB: "Public Bank",
    RHB: "RHB Bank",
    SCTB: "Standard Chartered Bank",
    UOB: "UOB Bank",
  },
};
