import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import {
  countryListAlpha3,
  lowMediumRiskCountries,
  highRiskCountries,
} from "../../../config/constants";
// import snsWebSdk from "@sumsub/websdk";
import {
  getSumSubAccessToken,
  storeSumSubApplicantId,
  setUserStartedUploadingKYCDocs,
} from "../../../redux/actions/userActions";

const Documents = props => {
  useEffect(() => {
    let levelName = "";
    if (
      lowMediumRiskCountries.includes(
        countryListAlpha3[props.auth.user.country]
      )
    ) {
      levelName = "Low/Medium Risk";
    }
    if (
      highRiskCountries.includes(countryListAlpha3[props.auth.user.country])
    ) {
      levelName = "High Risk";
    }
    if (props.auth.user.country === "NG") {
      levelName = "NigeriaLevel";
    }

    getSumSubAccessToken(levelName)
      .then(res => {
        // console.log("ACCESS TOKEN");
        // console.log(res.data);

        let accessToken = res.data;
        let applicantEmail = props.auth.user.email;
        let applicantPhone = props.auth.user.mobilePhone;

        let snsWebSdkInstance = window.snsWebSdk
          .init(accessToken, levelName => getNewAccessToken(levelName))
          .withConf({
            lang: "en",
            email: applicantEmail,
            phone: applicantPhone,
            i18n:
              props.auth.user.country === "NG"
                ? {
                    idTypes: {
                      RESIDENCE_PERMIT: "NIMS Slip",
                    },
                    document: {
                      instructions: {
                        RESIDENCE_PERMIT:
                          "Take a photo of your NIMS Slip. The photo should be: \n - **bright and clear** (good quality), \n - **uncut** (all corners of the document should be visible).",
                      },
                    },
                    components: {
                      uploadDocument: {
                        title: {
                          RESIDENCE_PERMIT:
                            'Please upload your <span class="accent">NIMS Slip</span>',
                        },
                      },
                    },
                  }
                : {},
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true })
          .on("idCheck.stepCompleted", payload => {
            handleSetUserStartedUploadingKYCDocs();
          })
          .on("idCheck.onError", error => {
            console.error("WebSDK onError", error);
          })
          .onMessage((type, payload) => {
            console.log("onMessage", type, payload);
            if (payload.applicantId) {
              // console.log(payload);
              console.log("storeSumSubApplicantId");
              storeSumSubApplicantId(payload.applicantId).catch(err =>
                console.error(err)
              );
            }
          })
          .build();

        // you are ready to go:
        // just launch the WebSDK by providing the container element for it
        snsWebSdkInstance.launch("#sumsub-websdk-container");
      })
      .catch(err => console.log(err));
  }, []);

  const getNewAccessToken = levelName => {
    return Promise.resolve(
      getSumSubAccessToken(levelName)
        .then(res => res.data)
        .catch(err => console.log(err))
    ); // get a new token from your backend
  };

  const handleSetUserStartedUploadingKYCDocs = () => {
    if (
      (!props.auth.user.eKYCPending && !localStorage.startedKYCProcess) ||
      (localStorage.startedKYCProcess &&
        localStorage.startedKYCProcess !== props.auth.user.id)
    ) {
      if (typeof window !== "undefined") {
        /* Facebook pixel event */
        if (window.fbq != null) {
          console.log("Trigger Facebook pixel event - Lead KYC");
          window.fbq("track", "Lead", { currency: "GBP", value: 0.0 });
        }
      }
      /* Facebook pixel event END */

      setUserStartedUploadingKYCDocs().catch(err => console.log(err));

      localStorage.setItem("startedKYCProcess", props.auth.user.id.toString());
    }
  };

  return (
    <Container className="mb-4" fluid>
      <Row>
        <Col>
          <h1 className="mx-md-4">Upload Documents</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <div id="sumsub-websdk-container" />
        </Col>
      </Row>
    </Container>
  );
};

Documents.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Documents);
