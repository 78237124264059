import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { FaCheck } from "react-icons/fa";
import { updateContactFreshworks } from "../../../../redux/actions/userActions";

const Step = props => {
  useEffect(() => {
    updateContactFreshworks({
      lifecycle: "EMAIL_VERIFICATION_VERIFIED",
    }).catch(err => console.log(err));

    /* Facebook pixel event */
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("trackCustom", "EmailVerification", { verified: true });
      }
    }
    /* Facebook pixel event END */

    setTimeout(() => {
      props.nextStep(1);
    }, 5000);
  }, [props]);

  return (
    <>
      <h1 className={classes.h1}>Verify your email</h1>

      <Card className={classes.card}>
        <Card.Body className="verifiedEmail">
          <div className="circle success checkmarkAnimation">
            <FaCheck className="checkmark" />
          </div>

          <div className="messageText pt-5 text-center mb-5 pb-5">
            Email verified!
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Step;
