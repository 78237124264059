import React, { useState } from "react";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { API_URL } from "../../../config/config";
import {
  addBankDetails,
  updateBankDetails,
} from "../../../redux/actions/userActions";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { bankCodesListReactSelect } from "../../../utils/utils";
import Select from "react-select";
import { availableWithdrawalBanksPerCountry } from "../../../config/africanSEAPaymentMethods";
// Register the plugin
registerPlugin(FilePondPluginFileRename, FilePondPluginImagePreview);

const BankDetails = props => {
  const [bankCode, setBankCode] = useState(null);
  const [poaFile, setPoaFile] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const newBankDetails = props.bankDetails === "";

  const fileID = props.auth.user.id;

  const handleChangeBankCode = e => {
    setBankCode(e.value);
  };

  const handleAddBankDetails = () => {
    const bankData = {
      userId: props.auth.user.id,
      bankCode,
      bankName:
        availableWithdrawalBanksPerCountry[props.auth.user.country][bankCode],
      bankCountry: props.auth.user.country,
      bankAccountNumber: document.getElementById("newBankAccountNumber").value,
      bankAccountName: document.getElementById("newBankAccountName").value,
      bankAccountCurrency: "USD",
      bankPOA: poaFile,
      africaSEALocalBank: true,
    };

    if (
      !bankCode ||
      bankData.bankAccountNumber.trim() === "" ||
      bankData.bankAccountName.trim() === ""
    ) {
      alert("You must fill all mandatory fields.");
      return false;
    }

    if (!poaFile) {
      alert("You must upload bank statement.");
      return false;
    }

    setBtnDisabled(true);
    // console.log(bankData);
    props.addBankDetails(bankData);
  };

  return newBankDetails ? (
    <Modal
      show={true}
      onHide={() => props.handleModalClose("showAddEditLocalBankDetailsModal")}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>New Local Bank Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            Local Bank <span className="text-danger">*</span>
          </Form.Label>
          <Select
            name="bankCode"
            required
            onChange={e => handleChangeBankCode(e)}
            options={bankCodesListReactSelect(
              availableWithdrawalBanksPerCountry[props.auth.user.country]
            )}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Account Number <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            id="newBankAccountNumber"
            className="roundedBox"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Account Name <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            id="newBankAccountName"
            className="roundedBox"
          />
        </Form.Group>

        <Form.Row>
          <Form.Group />
          <Form.Group className="text-right">
            <Form.Label /> <br />
            <span className="text-danger">*</span> Mandatory fields
          </Form.Group>
        </Form.Row>

        <br />

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              Please upload Bank Statement corresponding to the Bank Details
              entered above:
            </Form.Label>

            <FilePond
              onupdatefiles={fileItems => {
                if (fileItems.length > 0) {
                  setPoaFile(prevState => {
                    let files = fileItems.map(fileItem => fileItem.file);
                    if (files.length > 0) {
                      return files[0].name;
                    }
                  });
                }
              }}
              fileRenameFunction={file => {
                let date = new Date();
                let time = date.getTime();
                //prettier-ignore
                return `${fileID}_${time}_${file.basename}${file.extension}`;
              }}
              name={"file"}
              server={`${API_URL}/upload`}
            />
          </Form.Group>
        </Form.Row>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between">
        <Button
          variant="secondary"
          className="greyButton"
          onClick={() =>
            props.handleModalClose("showAddEditLocalBankDetailsModal")
          }
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="blueButton"
          style={{
            backgroundColor: "var(--color-dark-blue1)",
          }}
          onClick={handleAddBankDetails}
          disabled={btnDisabled}
        >
          {btnDisabled ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Submit
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal
      show={true}
      onHide={() => props.handleModalClose("showAddEditLocalBankDetailsModal")}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Local Bank Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Local Bank Name</Form.Label>
          <Form.Control
            type="text"
            className="roundedBox"
            defaultValue={props.bankDetails.bankName}
            disabled
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Bank Account Name</Form.Label>
          <Form.Control
            type="text"
            className="roundedBox"
            defaultValue={props.bankDetails.bankAccountName}
            disabled
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Bank Account Number</Form.Label>
          <Form.Control
            type="text"
            className="roundedBox"
            defaultValue={props.bankDetails.bankAccountNumber}
            disabled
          />
        </Form.Group>

        {props.bankDetails.bankPOA ? (
          <Form.Group>
            <Form.Label>Bank Statement: </Form.Label>{" "}
            <a
              href={`${API_URL}/public/files/${props.bankDetails.bankPOA}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          </Form.Group>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

BankDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  addBankDetails: PropTypes.func.isRequired,
  updateBankDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { addBankDetails, updateBankDetails })(
  BankDetails
);
