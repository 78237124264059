import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Button,
  Card,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import classes from "./Withdraw.module.css";
import {
  CRYPTO_NACE,
  CRYPTO_WITHDRAWAL_DISCLAIMER,
} from "../../../../config/config";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const coins = CRYPTO_NACE.coins;

  useEffect(() => {
    props.selectCryptoCoin(coins[0]);
  }, []);

  const handleUpdateAmount = e => {
    props.updateAmount(e.target.value);
  };

  const handleCoinSelect = coin => {
    props.selectCryptoCoin(coin);
  };

  const handleCryptoAddressChange = e => {
    props.selectCryptoAddress(e.target.value);
  };

  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders justify-content-between">
      <Col md="7">
        <div className="wizardStepsParagraph">
          Please enter the amount that you would like to withdraw:
        </div>

        <div style={!isMobileOnly ? { paddingLeft: "100px" } : {}}>
          <div className="fundingInputValuesWrapper">
            <Form.Control
              type="number"
              id="amount"
              name="amount"
              className="fundingInputValue h-100"
              placeholder="AMOUNT"
              onChange={e => handleUpdateAmount(e)}
              defaultValue={props.withdrawAmount || ""}
            />
            <span className="cardFlag cardCurrencyFlag">
              {props.selectedAccount.baseCurrency}
            </span>
          </div>

          {props.paymentMethod.id === "crypto" ? (
            <>
              <div className="my-3 fundingCryptoValuesWrapper">
                <InputGroup className="cryptoDropdownBottomMargin">
                  <Dropdown drop="up">
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      className="selectCryptoCoin"
                    >
                      {props.selectedCryptoCoin || coins[0]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="selectCryptoCoinItems">
                      {coins.map(coin => (
                        <Dropdown.Item
                          key={coin}
                          onClick={() => handleCoinSelect(coin)}
                        >
                          {coin}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <FormControl
                    placeholder="Enter your crypto address"
                    aria-label="Crypto Address"
                    aria-describedby="basic-addon2"
                    value={props.selectedCryptoAddress}
                    onChange={handleCryptoAddressChange}
                    className="border-0"
                  />
                </InputGroup>
              </div>
              <p
                className="p-2"
                dangerouslySetInnerHTML={{
                  __html: CRYPTO_WITHDRAWAL_DISCLAIMER,
                }}
              />
            </>
          ) : null}

          <div className="wizardFundAmount">
            <Button
              className="cardButton float-left mt-3 ml-1"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              onClick={() => props.nextStep("select_payment_method")}
            >
              BACK
            </Button>
            <Button
              className="cardButton float-right mt-3"
              style={{
                backgroundColor: "var(--color-dark-blue1)",
              }}
              onClick={props.selectAmount}
            >
              NEXT
            </Button>
          </div>
        </div>
      </Col>

      <Col md="5">
        <Card
          className={[
            "customCardsStyle customCardsStyleMediumFont",
            classes.PaymentMethodCardPreview,
          ].join(" ")}
        >
          <Card.Body>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">ACCOUNT NUMBER</div>
              {props.selectedAccount.number}
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">WITHDRAWAL METHOD</div>
              {props.paymentMethod.method}
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">
                BALANCE AVAILABLE TO WITHDRAW
              </div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.selectedAccount.balance).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
            <div style={{ height: "60px" }}>
              <div className="cardCaptionTitle">WITHDRAWAL AMOUNT</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {parseFloat(props.withdrawAmount).toFixed(2)}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
            <div>
              <div className="cardCaptionTitle">WITHDRAWAL FEE</div>
              <span className="d-inline-block" style={{ width: "110px" }}>
                {props.paymentMethod.fees}
              </span>
              <span className="cardFlag cardCurrencyFlag cardCurrencyFlagFee">
                {props.selectedAccount.baseCurrency}
              </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Step);
