import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import { updateCashierTransfer } from "../../../redux/actions/userActions";

const Step = props => {
  useEffect(() => {
    let returnQS = decodeURIComponent(props.location.search);
    let returnData = queryString.parse(returnQS);
    // console.log("returnData");
    // console.log(returnData);
    // console.log(props.match.params.returnData);
    props.updateCashierTransfer(returnData);
  }, [props.location.search]);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      // console.log("/deposit-funds/success");
      if (props.success.success)
        window.location.href = "/deposit-funds/success";
      else window.location.href = "/deposit-funds/failed";
    }

    if (Object.keys(props.errors).length > 0) {
      // console.log("/deposit-funds/failed");
      window.location.href = "/deposit-funds/failed";
    }
  }, [props]);

  return null;
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  updateCashierTransfer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateCashierTransfer })(Step);
