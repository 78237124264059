import React from "react";
// import { connect } from "react-redux";
// import { FaChartLine, FaAddressCard, FaPassport, FaMoneyBillAlt, FaExchangeAlt, FaDownload } from "react-icons/fa";
// import classes from "./Sidebar.module.css";
import logo from "../../assets/images/iQuoto-Global-Logo-White.svg";
import SidebarUserLinks from "./SidebarUserLinks";

const SidebarUser = () => {
  return (
    <div className="sidebarUser">
      <object
        data={logo}
        width="172"
        height="65"
        className="sidebarLogo"
        aria-label="iQuoto.com"
      />

      <SidebarUserLinks />
    </div>
  );
};

export default SidebarUser;
