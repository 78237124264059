import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  countryListReactSelect,
  getBirthDaysReactSelect,
  getBirthMonthsReactSelect,
  getBirthYearsReactSelect,
} from "../../../utils/utils";
import Select from "react-select";
import { registerUser } from "../../../redux/actions/authActions";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import classes from "./RegisterNew.module.css";

// SOCIAL LOGIN DEPENDENCIES
// import io from "socket.io-client";
// import OAuth from "../SocialLogin/OAuth";
// const socket = io(API_URL);
// const providers = ["twitter", "google", "facebook"];
// SOCIAL LOGIN DEPENDENCIES END

class Register extends Component {
  state = {
    title: "",
    name: "",
    surname: "",
    email: "",
    password: "",
    password2: "",
    birthDay: "",
    birthMonth: "",
    birthYear: "",
    mobilePhone: "",
    addressNo: "",
    address: "",
    city: "",
    postcode: "",
    county: "",
    country: "",
    socialId: "",
    validated: false,
    disableSignupBtn: false,
    errors: {},
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/trading-accounts");
    }
  }

  static getDerivedStateFromProps(newProps) {
    if (Object.keys(newProps.errors).length > 0) {
      return {
        errors: { ...newProps.errors },
        disableSignupBtn: false,
      };
    }

    // No state update necessary
    return null;
  }

  handleChange = (e, target) => {
    let name;
    let value;

    if (
      target === "country" ||
      target === "birthDay" ||
      target === "birthMonth" ||
      target === "birthYear"
    ) {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    this.setState({ disableSignupBtn: true });

    if (
      this.state.birthDay === undefined ||
      this.state.birthMonth === undefined ||
      this.state.birthYear === undefined
    ) {
      this.setState(prevState => {
        return { ...prevState, disableSignupBtn: false };
      });

      alert("Please, set your birth date.");

      return false;
    }

    if (form.checkValidity()) {
      const newUser = {
        title: this.state.title,
        name: this.state.name,
        surname: this.state.surname,
        email: this.state.email,
        password: this.state.password,
        password2: this.state.password2,
        birthDay: this.state.birthDay,
        birthMonth: this.state.birthMonth,
        birthYear: this.state.birthYear,
        mobilePhone: this.state.mobilePhone,
        addressNo: this.state.addressNo,
        address: this.state.address,
        city: this.state.city,
        postcode: this.state.postcode,
        county: this.state.county,
        country: this.state.country,
      };

      this.props.registerUser(newUser, this.props.history);
    }
  };

  render() {
    const { validated, errors, disableSignupBtn } = this.state;

    return (
      <>
        <Container>
          <Row className="justify-content-lg-center">
            <Col>
              <h1>Register</h1>
            </Col>
          </Row>

          <Row className="justify-content-lg-center">
            {this.state.success ? (
              <Col lg="7">
                <br />
                <br />
                <br />
                <div className="text-success text-center pb-2">
                  {this.state.successMessage}
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </Col>
            ) : (
              <Col>
                <Form
                  validated={validated}
                  onSubmit={e => this.handleSubmit(e)}
                >
                  <Form.Text style={{ color: "red" }}>
                    {errors.server}
                  </Form.Text>

                  <Form.Row>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        required
                        onChange={e => this.handleChange(e, "")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.email}
                      </Form.Text>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>
                        Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        required
                        onChange={e => this.handleChange(e, "")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.password}
                        {errors.passwordincorrect}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>
                        Confirm password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password2"
                        placeholder="Confirm password"
                        required
                        onChange={e => this.handleChange(e, "")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.password2}
                      </Form.Text>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md="2" xs="6">
                      <Form.Label className={classes.label}>Title</Form.Label>
                      <Form.Control
                        as="select"
                        name="title"
                        required
                        onChange={e => this.handleChange(e, "")}
                      >
                        <option value="">Select title</option>
                        <option>Mr.</option>
                        <option>Mrs.</option>
                        <option>Dr.</option>
                      </Form.Control>
                      <Form.Text style={{ color: "red" }}>
                        {errors.title}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} md="4" xs="6">
                      <Form.Label className={classes.label}>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        required
                        onChange={e => this.handleChange(e, "")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.name}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>Surname</Form.Label>
                      <Form.Control
                        type="text"
                        name="surname"
                        placeholder="Enter your surname"
                        required
                        onChange={e => this.handleChange(e, "")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.surname}
                      </Form.Text>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Col md="6" xs="12">
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label className={classes.label}>
                            Birth day
                          </Form.Label>
                          <Select
                            name="birthDay"
                            required
                            onChange={e => this.handleChange(e, "birthDay")}
                            options={getBirthDaysReactSelect()}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label className={classes.label}>
                            Birth month
                          </Form.Label>
                          <Select
                            name="birthMonth"
                            required
                            onChange={e => this.handleChange(e, "birthMonth")}
                            options={getBirthMonthsReactSelect()}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label className={classes.label}>
                            Birth year
                          </Form.Label>
                          <Select
                            name="birthYear"
                            required
                            onChange={e => this.handleChange(e, "birthYear")}
                            options={getBirthYearsReactSelect()}
                          />
                        </Form.Group>
                      </Form.Row>
                    </Col>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>
                        Mobile phone
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="mobilePhone"
                        placeholder="Enter your phone number"
                        required
                        onChange={e => this.handleChange(e, "")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.mobilePhone}
                      </Form.Text>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>
                        First Line Address
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="addressNo"
                        required
                        onChange={e => this.handleChange(e, "")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.address}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>
                        Second Line Address
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        onChange={e => this.handleChange(e, "")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.address}
                      </Form.Text>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        placeholder="Enter your city"
                        required
                        onChange={e => this.handleChange(e, "")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.city}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>
                        Postcode
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="postcode"
                        placeholder="Enter your postcode"
                        required
                        onChange={e => this.handleChange(e, "")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.postcode}
                      </Form.Text>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>County</Form.Label>
                      <Form.Control
                        type="text"
                        name="county"
                        placeholder="Enter your county"
                        required
                        onChange={e => this.handleChange(e, "county")}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.county}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label className={classes.label}>Country</Form.Label>
                      <Select
                        name="country"
                        required
                        onChange={e => this.handleChange(e, "country")}
                        options={countryListReactSelect()}
                      />
                      <Form.Text style={{ color: "red" }}>
                        {errors.country}
                      </Form.Text>
                    </Form.Group>
                  </Form.Row>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={disableSignupBtn}
                  >
                    {disableSignupBtn ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Register
                      </>
                    ) : (
                      "Register"
                    )}
                  </Button>
                </Form>

                <br />

                {/*{providers.map(provider => (*/}
                {/*  <OAuth*/}
                {/*    provider={provider}*/}
                {/*    key={provider}*/}
                {/*    socket={socket}*/}
                {/*    loginUser={registerUser}*/}
                {/*    pageAction="register"*/}
                {/*  />*/}
                {/*))}*/}
              </Col>
            )}
          </Row>
        </Container>
      </>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  success: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
