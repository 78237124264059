import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { updateTradingPreferencesWeb } from "../../../../redux/actions/authActions";
import { FaDollarSign, FaEuroSign, FaPoundSign } from "react-icons/fa";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!!localStorage.userData) {
      setUserData(prevState => {
        return {
          ...prevState,
          ...JSON.parse(localStorage.userData),
        };
      });
    }
  }, []);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 4) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(1);
      }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleChange = (value, target) => {
    setUserData(prevState => {
      return {
        ...prevState,
        [target]: value,
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    setDisableButton(true);

    const tradingPreferences = {
      step: 4,
      subStep: 1,
      userId: props.auth.user.id,
      baseAccountCurrency: userData.baseAccountCurrency,
    };

    props.updateTradingPreferencesWeb(tradingPreferences);
  };

  return (
    <>
      {isMobileOnly ? (
        <>
          <h1 className={classes.h1}>Account details</h1>
          <h2 className={classes.h2}>Base currency</h2>
        </>
      ) : (
        <h1 className={classes.h1}>Base currency</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col} className="mb-0">
                <Form.Label className={classes.label}>
                  Please choose the base currency of your trading account:
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={classes.bigChoiceBoxCheckWrapper}
                onClick={() => handleChange("USD", "baseAccountCurrency")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.baseAccountCurrency &&
                    userData.baseAccountCurrency === "USD"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxCheck}>
                    <FaDollarSign className={classes.checkmark} />
                  </span>
                  <span className={classes.bigChoiceBoxBottom}>USD</span>
                </Form.Text>
              </Form.Group>
              <Form.Group
                as={Col}
                className={classes.bigChoiceBoxCheckWrapper}
                onClick={() => handleChange("EUR", "baseAccountCurrency")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.baseAccountCurrency &&
                    userData.baseAccountCurrency === "EUR"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxCheck}>
                    <FaEuroSign className={classes.checkmark} />
                  </span>
                  <span className={classes.bigChoiceBoxBottom}>EUR</span>
                </Form.Text>
              </Form.Group>
              <Form.Group
                as={Col}
                className={classes.bigChoiceBoxCheckWrapper}
                onClick={() => handleChange("GBP", "baseAccountCurrency")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.baseAccountCurrency &&
                    userData.baseAccountCurrency === "GBP"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxCheck}>
                    <FaPoundSign className={classes.checkmark} />
                  </span>
                  <span className={classes.bigChoiceBoxBottom}>GBP</span>
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>
            <Button
              variant="link"
              className="simpleBackButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="blueButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  updateTradingPreferencesWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateTradingPreferencesWeb })(Step);
