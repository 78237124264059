import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { getUser, editUser } from "../../../redux/actions/authActions";
import { countryList } from "../../../config/constants";
import { NotificationManager } from "react-notifications";

const profile = props => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState({
    selectedUser: {},
    disableEditUserBtn: false,
    errors: {},
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getUser(props.auth.user.email)
      .then(res => {
        setState(prevState => {
          return {
            ...prevState,
            selectedUser: res.data.user,
          };
        });
      })
      .catch(err => console.log(err));
  }, [props]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      // window.location.reload();
      NotificationManager.success(
        "You have successfully changed your password.",
        "Password Changed"
      );
    }

    if (Object.keys(props.errors).length > 0) {
      setState(prevState => {
        return { ...prevState, errors: props.errors };
      });
    }

    setState(prevState => {
      return {
        ...prevState,
        disableEditUserBtn: false,
      };
    });
  }, [props]);

  const handleChangeProfile = e => {
    let { name, value } = e.target;

    const selectedUser = state.selectedUser || {};
    selectedUser[name] = value;
    setState(prevState => {
      return { ...prevState, selectedUser };
    });
  };

  const handleSubmitProfile = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setState(prevState => {
      return { ...prevState, disableEditUserBtn: true };
    });

    const { selectedUser } = state;

    if (form.checkValidity()) {
      props.editUser({
        password: selectedUser.password,
        password2: selectedUser.password2,
      });
    }
  };

  return (
    <Container>
      <Row className="justify-content-lg-center">
        <Col>
          <h1>Profile</h1>
        </Col>
      </Row>
      <Row className="justify-content-lg-center">
        <Col>
          <Card.Body>
            <Form onSubmit={e => handleSubmitProfile(e)}>
              <Form.Text style={{ color: "red" }}>
                {props.errors.server}
              </Form.Text>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Email</Form.Label>
                  <Form.Text className="font-italic">
                    {state.selectedUser.email}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>New password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    onChange={e => handleChangeProfile(e)}
                    required
                  />
                  <Form.Text style={{ color: "red" }}>
                    {props.errors.passwordincorrect}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Confirm password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password2"
                    placeholder="Confirm password"
                    onChange={e => handleChangeProfile(e)}
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="2" xs="6">
                  <Form.Label>Title</Form.Label>
                  <Form.Text className="font-italic">
                    {state.selectedUser.title || "-"}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} md="4" xs="6">
                  <Form.Label>Name</Form.Label>
                  <Form.Text className="font-italic">
                    {state.selectedUser.name || "-"}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Surname</Form.Label>
                  <Form.Text className="font-italic">
                    {state.selectedUser.surname || "-"}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Col md="6" xs="12">
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Birthday</Form.Label>
                      <Form.Text className="font-italic">{`${state.selectedUser.birthYear}-${state.selectedUser.birthMonth}-${state.selectedUser.birthDay}`}</Form.Text>
                    </Form.Group>
                  </Form.Row>
                </Col>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Mobile phone</Form.Label>
                  <Form.Text className="font-italic">
                    {state.selectedUser.mobilePhone || "-"}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>First Line Address</Form.Label>
                  <Form.Text className="font-italic">
                    {state.selectedUser.address || "-"}
                  </Form.Text>
                </Form.Group>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Second Line Address</Form.Label>
                  <Form.Text className="font-italic">
                    {state.selectedUser.address || "-"}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>City</Form.Label>
                  <Form.Text className="font-italic">
                    {state.selectedUser.city || "-"}
                  </Form.Text>
                </Form.Group>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Postcode</Form.Label>
                  <Form.Text className="font-italic">
                    {state.selectedUser.postcode || "-"}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>County</Form.Label>
                  <Form.Text className="font-italic">
                    {state.selectedUser.county || "-"}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Country</Form.Label>
                  <Form.Text className="font-italic">
                    {countryList[state.selectedUser.country] || "-"}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Nationality</Form.Label>
                  <Form.Text className="font-italic">
                    {countryList[state.selectedUser.nationality] || "-"}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Button
                  className="blueButton"
                  type="submit"
                  disabled={state.disableEditUserBtn}
                >
                  {state.disableEditUserBtn ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Submit
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form.Row>
            </Form>
          </Card.Body>
        </Col>
      </Row>
    </Container>
  );
};

profile.propTypes = {
  auth: PropTypes.object.isRequired,
  editUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  editUser,
})(profile);
