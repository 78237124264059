import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { editUserWeb } from "../../../../redux/actions/authActions";
import orderExecutionPolicy from "../../../../assets/documents/Order_Execution_Policy_v1.1.pdf";
import privacyPolicy from "../../../../assets/documents/Privacy_Policy_v1.1.pdf";
import riskDisclosure from "../../../../assets/documents/Risk_Disclosure_v1.1.pdf";
import termsOfBusiness from "../../../../assets/documents/Terms_of_Business_v1.1.pdf";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 5) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(1);
      }
      // if (
      //   props.success.step === 5 &&
      //   !props.auth.user.registered &&
      //   Object.entries(props.auth.user).length > 0
      // ) {
      //   // Logout user
      //   store.dispatch(logoutUser());
      //   // Redirect to login
      //   window.location.href = "/login";
      // }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    const acceptDeclaration =
      document.getElementById("acceptDeclaration").checked;

    if (
      acceptDeclaration ||
      (!acceptDeclaration &&
        window.confirm("I Agree to all the above declarations."))
    ) {
      const editUser = {
        step: 5,
        subStep: 2,
        userId: props.auth.user.id,
        acceptDeclaration: true,
        registered: true,
      };
      setDisableButton(true);
      props.editUserWeb(editUser);
    }
  };

  return (
    <>
      {isMobileOnly ? (
        <>
          <h1 className={classes.h1}>Legal</h1>
          <h2 className={classes.h2}>Declaration</h2>
        </>
      ) : (
        <h1 className={classes.h1}>Declaration</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Label className={classes.label}>
                Client Declaration
              </Form.Label>
              <Form.Text className="pb-3">
                1. I understand and accept that iQuoto International will
                provide services to me in accordance with the following
                documents which, for my own benefit and protection, I should
                read:{" "}
                <Button
                  variant="link"
                  className="orangeLink"
                  href={termsOfBusiness}
                  target="_blank"
                >
                  Terms of Business
                </Button>
                ,{" "}
                <Button
                  variant="link"
                  className="orangeLink"
                  href={orderExecutionPolicy}
                  target="_blank"
                >
                  Order Execution Policy
                </Button>{" "}
                and{" "}
                <Button
                  variant="link"
                  className="orangeLink"
                  href={riskDisclosure}
                  target="_blank"
                >
                  Risk Disclosure
                </Button>
                .
              </Form.Text>
            </Form.Row>

            <Form.Row>
              <Form.Text className="pb-3">
                2. The information I have provided to iQuoto International about
                myself is accurate and not misleading in any material respect. I
                will notify iQuoto International immediately if any of
                information materially changes or ceases to be true and correct.
              </Form.Text>
            </Form.Row>

            <Form.Row>
              <Form.Text className="pb-3">
                3. I agree and understand that iQuoto International has a
                legitimate interest in contacting me by email, sms, post, or
                phone in connection with my application to ensure that I am
                aware of all the services available to me, or for other
                marketing purposes. I understand that I can opt out of receiving
                marketing communications immediately by contacting the Client
                Services team. I understand that further information on how
                iQuoto International processes client data is contained in
                iQuoto Internationals'{" "}
                <Button
                  variant="link"
                  className="orangeLink"
                  href={privacyPolicy}
                  target="_blank"
                >
                  Privacy Policy
                </Button>
                .
              </Form.Text>
            </Form.Row>

            <Form.Row>
              <Form.Group
                as={Col}
                className="mt-4"
                style={{ maxWidth: "28px" }}
              >
                <Form.Check
                  custom
                  inline
                  label=""
                  type="checkbox"
                  id="acceptDeclaration"
                />
              </Form.Group>
              <Form.Group as={Col} className="mt-4">
                <label
                  className={classes.smallLabel}
                  htmlFor="acceptDeclaration"
                >
                  I Agree to all the above declarations
                </label>
              </Form.Group>
            </Form.Row>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>
            <Button
              variant="link"
              className="simpleBackButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="greenButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  SUBMIT
                </>
              ) : (
                "SUBMIT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  editUserWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { editUserWeb })(Step);
