import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  requestAWallet,
  updateAccount,
  requestCloseAccount,
  clearSuccessErrorProps,
} from "../../../redux/actions/userActions";

const WalletDetails = props => {
  const [state, setState] = useState({
    addWalletErrors: "",
    showAddWalletModal: false,
    loading: true,
  });
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState(null);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      clearSuccessErrorProps();

      if (props.success.walletRequested) {
        props.createNotification("walletRequested");
      }
      setBtnDisabled(false);

      if (!props.success.success) {
        setState(prevState => {
          return {
            ...prevState,
            addWalletErrors: props.success.addWalletErrors,
          };
        });
      } else {
        props.handleModalClose("showAddWalletModal");
        props.triggerGetWalletAccounts();
      }
    }

    if (
      Object.keys(props.errors).length > 0 &&
      props.errors.addWalletErrors !== undefined
    ) {
      clearSuccessErrorProps();

      setState(prevState => {
        return {
          ...prevState,
          addWalletErrors: props.errors.addWalletErrors,
        };
      });
      setBtnDisabled(false);
    }
  }, [props.success, props.errors]);

  const handleChangeBaseCurrency = e => {
    setBaseCurrency(e.target.value);
  };

  const handleRequestAWallet = () => {
    const accData = {
      userId: props.auth.user.id,
      baseCurrency,
    };

    if (!accData.baseCurrency || accData.baseCurrency === "") {
      return false;
    }

    setBtnDisabled(true);

    props.requestAWallet(accData);
  };

  return (
    <Modal
      show={true}
      onHide={() => props.handleModalClose("showAddWalletModal")}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create a Wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="newBaseCurrency">
          <Form.Label>Base currency</Form.Label>
          <Form.Control
            as="select"
            className="roundedBox"
            onChange={e => handleChangeBaseCurrency(e)}
            required
          >
            <option value="">Select wallet currency</option>
            <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
            <option value="USD">USD</option>
          </Form.Control>
        </Form.Group>

        <Form.Text style={{ color: "red" }}>
          {props.errors.server}
          {state.addWalletErrors}
        </Form.Text>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between">
        <Button
          variant="secondary"
          className="greyButton"
          onClick={() => props.handleModalClose("showAddWalletModal")}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="blueButton"
          style={{
            fontWeight: "normal",
          }}
          disabled={btnDisabled}
          onClick={handleRequestAWallet}
        >
          {btnDisabled ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Submit
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

WalletDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  requestAWallet: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  requestCloseAccount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  requestAWallet,
  updateAccount,
  requestCloseAccount,
})(WalletDetails);
