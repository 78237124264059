import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTransferHistory } from "../../../redux/actions/userActions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Container, Row, Col, Spinner, Tabs, Tab } from "react-bootstrap";
import moment from "moment";

const MyTransferHistory = () => {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState(null);

  useEffect(() => {
    getTransferHistory()
      .then(res => {
        setHistory(res.data);
        setLoading(false);
      })
      .catch(err => console.error(err));
  }, []);

  const columnsDeposits = [
    {
      dataField: "accountNumber",
      text: "Acc. number",
      sort: true,
    },
    {
      dataField: "depositType",
      text: "Type",
      sort: true,
    },
    {
      dataField: "cryptoCurrency",
      text: "Crypto Currency",
      sort: true,
    },
    {
      dataField: "cryptoSourceAddress",
      text: "Crypto Address",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "cryptoTransactionId",
      text: "Crypto Tx Id",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "depositAmountSent",
      text: "Deal amount",
      sort: true,
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "comment",
      text: "Comment",
    },
    {
      dataField: "timestamp",
      text: "Requested",
      sort: true,
    },
    {
      dataField: "timeUpdated",
      text: "Updated",
      sort: true,
    },
  ];

  const columnsWithdrawals = [
    {
      dataField: "accountNumber",
      text: "Acc. number",
      sort: true,
    },
    {
      dataField: "withdrawalType",
      text: "Type",
      sort: true,
    },
    {
      dataField: "cryptoCurrency",
      text: "Crypto Currency",
      sort: true,
    },
    {
      dataField: "cryptoTargetAddress",
      text: "Crypto Address",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "cryptoTransactionId",
      text: "Crypto Tx Id",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "withdrawalAmount",
      text: "Amount",
      sort: true,
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "comment",
      text: "Comment",
    },
    {
      dataField: "timestamp",
      text: "Requested",
      sort: true,
    },
    {
      dataField: "timeUpdated",
      text: "Updated",
      sort: true,
    },
  ];

  const columnsTransfers = [
    {
      dataField: "sourceAccount",
      text: "Src. account",
      sort: true,
    },
    {
      dataField: "destinationAccount",
      text: "Dest. account",
      sort: true,
    },
    {
      dataField: "transferAmount",
      text: "Amount",
      sort: true,
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "comment",
      text: "Comment",
    },
    {
      dataField: "timestamp",
      text: "Requested",
      sort: true,
    },
    {
      dataField: "timeUpdated",
      text: "Updated",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
    ],
  });

  let dataDeposits = [];
  if (!!history && history.deposits) {
    Object.keys(history.deposits).forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(history.deposits[keyIndex].timestamp)) {
        history.deposits[keyIndex].timestamp = moment(
          history.deposits[keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();

        history.deposits[keyIndex].depositType = history.deposits[
          keyIndex
        ].depositType
          .replace("_", " ")
          .toUpperCase();

        if (history.deposits[keyIndex].status) {
          history.deposits[keyIndex].status =
            history.deposits[keyIndex].status.toUpperCase();
        }

        if (
          history.deposits[keyIndex].status === "approved" &&
          history.deposits[keyIndex].timeApproved
        ) {
          history.deposits[keyIndex].timeUpdated = moment(
            history.deposits[keyIndex].timeApproved * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
        if (
          history.deposits[keyIndex].status === "rejected" &&
          history.deposits[keyIndex].timeRejected
        ) {
          history.deposits[keyIndex].timeUpdated = moment(
            history.deposits[keyIndex].timeRejected * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
      }

      history.deposits[keyIndex].depositAmountSent = `${parseFloat(
        history.deposits[keyIndex].depositAmountSent
      ).toFixed(2)} ${history.deposits[keyIndex].depositCurrency}`;

      dataDeposits.push(history.deposits[keyIndex]);
    });
  }

  let dataWithdrawals = [];
  if (!!history && history.withdrawals) {
    Object.keys(history.withdrawals).forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(history.withdrawals[keyIndex].timestamp)) {
        history.withdrawals[keyIndex].timestamp = moment(
          history.withdrawals[keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();

        history.withdrawals[keyIndex].withdrawalType = history.withdrawals[
          keyIndex
        ].withdrawalType
          .replace("_", " ")
          .toUpperCase();

        if (history.withdrawals[keyIndex].status) {
          history.withdrawals[keyIndex].status =
            history.withdrawals[keyIndex].status.toUpperCase();
        }

        if (
          history.withdrawals[keyIndex].status === "approved" &&
          history.withdrawals[keyIndex].timeApproved
        ) {
          history.withdrawals[keyIndex].timeUpdated = moment(
            history.withdrawals[keyIndex].timeApproved * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
        if (
          history.withdrawals[keyIndex].status === "rejected" &&
          history.withdrawals[keyIndex].timeRejected
        ) {
          history.withdrawals[keyIndex].timeUpdated = moment(
            history.withdrawals[keyIndex].timeRejected * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
      }

      history.withdrawals[keyIndex].withdrawalAmount = `${parseFloat(
        history.withdrawals[keyIndex].withdrawalAmount
      ).toFixed(2)} ${history.withdrawals[keyIndex].withdrawalCurrency}`;

      dataWithdrawals.push(history.withdrawals[keyIndex]);
    });
  }

  let dataTransfers = [];
  if (!!history && history.transfers) {
    Object.keys(history.transfers).forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(history.transfers[keyIndex].timestamp)) {
        history.transfers[keyIndex].timestamp = moment(
          history.transfers[keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();

        if (history.transfers[keyIndex].status) {
          history.transfers[keyIndex].status =
            history.transfers[keyIndex].status.toUpperCase();
        }

        if (
          history.transfers[keyIndex].status === "approved" &&
          history.transfers[keyIndex].timeApproved
        ) {
          history.transfers[keyIndex].timeUpdated = moment(
            history.transfers[keyIndex].timeApproved * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
        if (
          history.transfers[keyIndex].status === "rejected" &&
          history.transfers[keyIndex].timeRejected
        ) {
          history.transfers[keyIndex].timeUpdated = moment(
            history.transfers[keyIndex].timeRejected * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
      }

      history.transfers[keyIndex].transferAmount = `${parseFloat(
        history.transfers[keyIndex].transferAmount
      ).toFixed(2)} ${history.transfers[keyIndex].transferCurrency}`;

      dataTransfers.push(history.transfers[keyIndex]);
    });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="mx-md-4">
              Transactions History{" "}
              {loading ? <Spinner animation="border" /> : null}
            </h1>
          </Col>
        </Row>
        <Row className="mx-md-4">
          <Col className="px-0">
            <Tabs
              defaultActiveKey="deposits"
              transition={false}
              id="transfer-history-tabs"
            >
              <Tab eventKey="deposits" title="DEPOSITS" className="">
                <BootstrapTable
                  bootstrap4
                  keyField="_id"
                  data={dataDeposits}
                  columns={columnsDeposits}
                  defaultSorted={defaultSorted}
                  hover={true}
                  pagination={pagination}
                />
              </Tab>
              <Tab eventKey="withdrawals" title="WITHDRAWALS" className="">
                <BootstrapTable
                  bootstrap4
                  keyField="_id"
                  data={dataWithdrawals}
                  columns={columnsWithdrawals}
                  defaultSorted={defaultSorted}
                  hover={true}
                  pagination={pagination}
                />
              </Tab>
              <Tab eventKey="transfer" title="INTERNAL TRANSFERS" className="">
                <BootstrapTable
                  bootstrap4
                  keyField="_id"
                  data={dataTransfers}
                  columns={columnsTransfers}
                  defaultSorted={defaultSorted}
                  hover={true}
                  pagination={pagination}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

MyTransferHistory.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MyTransferHistory);
