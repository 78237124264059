import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import jwt_decode from "jwt-decode";
import setAuthToken from "./redux/utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./redux/actions/authActions";
import { Provider } from "react-redux";
import store from "./redux/store";
// import IdleTimer from "react-idle-timer";

import Layout from "./hoc/Layout/Layout";
// import Homepage from "./containers/Pages/Homepage/Homepage";

// User section
import Marketing from "./containers/Marketing/Marketing";
import FacebookEvents from "./containers/Marketing/FacebookEvents";
import Login from "./containers/Auth/Login/Login";
import VerifyLogin from "./containers/Auth/Login/VerifyLogin";
import Register from "./containers/Auth/Register/Register";
import RegisterNew from "./containers/Auth/Register/RegisterNew";
import ConfirmEmail from "./containers/Auth/ConfirmEmail/ConfirmEmail";
import ForgotPassword from "./containers/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword/ResetPassword";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Accounts from "./containers/Pages/Accounts/Accounts";
import WalletAccounts from "./containers/Pages/Accounts/WalletAccounts";
import Profile from "./containers/Pages/Profile/Profile";
// import Documents from "./containers/Pages/Documents/Documents";
import CloseThisWindow from "./components/CloseThisWindow";
// import UploadDocuments from "./containers/Pages/Documents/UploadDocuments";
import UploadDocuments from "./containers/Pages/Documents/UploadDocumentsSumSub";
import MyTransferHistory from "./containers/Pages/MyTransferHistory/MyTransferHistory";
// import TradingHistory from "./containers/Pages/TradingHistory/TradingHistory";
import DepositFunds from "./containers/Pages/Funds/Deposit/Deposit";
import WithdrawFunds from "./containers/Pages/Funds/Withdraw/Withdraw";
import InternalTransfer from "./containers/Pages/Funds/InternalTransfer/InternalTransfer";
import SafeChargePaymentReturn from "./containers/Pages/Funds/SafeChargePaymentReturn";
import SafeChargeWithdrawalReturn from "./containers/Pages/Funds/SafeChargeWithdrawalReturn";
// import ZotaPayDepositReturn from "./containers/Pages/Funds/ZotaPayDepositReturn";
// import ZotaPayWithdrawalReturn from "./containers/Pages/Funds/ZotaPayWithdrawalReturn";
import Platforms from "./containers/Pages/Platforms/Platforms";
import BankDetails from "./containers/Pages/BankDetails/BankDetails";

import "./App.css";

// PLUGINS
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "react-notifications/lib/notifications.css";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/";
  }
}

class App extends Component {
  // constructor(props) {
  //   super(props);
  //
  //   this.state = {
  //     timeout: 1000 * 60 * 10, // 10 minutes
  //     showModal: false,
  //     userLoggedIn: false,
  //     isTimedOut: false
  //   };
  //
  //   this.idleTimer = null;
  //   this.onAction = this._onAction.bind(this);
  //   this.onActive = this._onActive.bind(this);
  //   this.onIdle = this._onIdle.bind(this);
  // }
  //
  // _onAction(e) {
  //   // console.log("user did something", e);
  //   this.setState({ isTimedOut: false });
  // }
  //
  // _onActive(e) {
  //   // console.log("user is active", e);
  //   this.setState({ isTimedOut: false });
  // }
  //
  // _onIdle(e) {
  //   // console.log("user is idle", e);
  //   const isTimedOut = this.state.isTimedOut;
  //   if (isTimedOut && !window.location.href.includes("login")) {
  //     // Logout user
  //     store.dispatch(logoutUser());
  //     // Redirect to login
  //     window.location.href = "/login";
  //   } else {
  //     this.setState({ showModal: true });
  //     this.idleTimer.reset();
  //     this.setState({ isTimedOut: true });
  //   }
  // }

  render() {
    return (
      <Provider store={store}>
        <Router>
          {/*<IdleTimer*/}
          {/*  ref={ref => {*/}
          {/*    this.idleTimer = ref;*/}
          {/*  }}*/}
          {/*  element={document}*/}
          {/*  onActive={this.onActive}*/}
          {/*  onIdle={this.onIdle}*/}
          {/*  onAction={this.onAction}*/}
          {/*  debounce={250}*/}
          {/*  timeout={this.state.timeout}*/}
          {/*  events={["keydown", "mousedown"]}*/}
          {/*/>*/}

          <Layout>
            <Route path="/mkt/:code" exact component={Marketing} />
            <Route
              path="/facebookEvents/:code"
              exact
              component={FacebookEvents}
            />
            <Route
              path="/facebookEvents/:code/:extra"
              exact
              component={FacebookEvents}
            />

            <Route path="/" exact component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/confirm-login" component={VerifyLogin} />
            <Route path="/register" exact component={RegisterNew} />
            <Route path="/register/:introducer" exact component={RegisterNew} />
            <Route path="/register2" component={Register} />
            <Route path="/forgot-password" component={ForgotPassword} />

            <Switch>
              {/* USER ROUTES */}
              <PrivateRoute
                exact
                path="/trading-accounts"
                component={Accounts}
              />
              <PrivateRoute
                exact
                path="/wallet-accounts"
                component={WalletAccounts}
              />
              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/trading-history"*/}
              {/*  component={TradingHistory}*/}
              {/*/>*/}
              <PrivateRoute
                exact
                path="/deposit-funds"
                component={DepositFunds}
              />
              <PrivateRoute
                exact
                path="/deposit-funds/account/:account"
                component={DepositFunds}
              />
              <PrivateRoute
                exact
                path="/deposit-funds/:status"
                component={DepositFunds}
              />
              <PrivateRoute
                exact
                path="/withdraw-funds"
                component={WithdrawFunds}
              />
              <PrivateRoute
                exact
                path="/withdraw-funds/:status"
                component={WithdrawFunds}
              />
              <PrivateRoute
                exact
                path="/internal-transfer"
                component={InternalTransfer}
              />
              <PrivateRoute
                exact
                path="/bank-details"
                component={BankDetails}
              />
              <PrivateRoute exact path="/profile" component={Profile} />
              {/*<PrivateRoute exact path="/documents" component={Documents} />*/}
              <PrivateRoute
                exact
                path="/upload-documents"
                component={UploadDocuments}
              />
              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/document/:documentType"*/}
              {/*  component={UploadDocuments}*/}
              {/*/>*/}
              <PrivateRoute
                exact
                path="/payments/:returnData"
                component={SafeChargePaymentReturn}
              />
              <PrivateRoute
                exact
                path="/withdrawal/:returnData"
                component={SafeChargeWithdrawalReturn}
              />
              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/zotapay-payments"*/}
              {/*  component={ZotaPayDepositReturn}*/}
              {/*/>*/}
              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/zotapay-withdrawal"*/}
              {/*  component={ZotaPayWithdrawalReturn}*/}
              {/*/>*/}
              <PrivateRoute
                exact
                path="/transactions-history"
                component={MyTransferHistory}
              />

              <PrivateRoute
                exact
                path="/platforms/:target"
                component={Platforms}
              />
            </Switch>

            <Route path="/email/confirm/:id+" component={ConfirmEmail} />
            <Route
              path="/email/confirm/:setpassword/:id+"
              component={ConfirmEmail}
            />
            <Route path="/resetpassword/:id+" component={ResetPassword} />

            <Route path="/ideal" component={CloseThisWindow} />
          </Layout>
        </Router>
      </Provider>
    );
  }
}

export default App;
