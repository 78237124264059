import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestCryptoDeposit } from "../../../../redux/actions/userActions";
import { Row, Col } from "react-bootstrap";
import { CRYPTO_NACE } from "../../../../config/config";
import { addFee } from "../../../../utils/utils";
import btnCryptoPay from "../../../../assets/images/funding-methods/fundingMethodCrypto.svg";

const Step = props => {
  const [paymentPrepared, setPaymentPrepared] = useState(false);
  const nacePayDivRef = useRef(null);

  window.widgetCallBack = (a, l, e, t, r, c) => {
    props.nextStep("success_crypto");
  };

  function PaymentComponent() {
    return (
      <div
        ref={nacePayDivRef}
        nacepay="Paywidget"
        nacepay_id={CRYPTO_NACE.widgetId}
        amount={addFee(props.depositAmount, CRYPTO_NACE.feePercentage)}
        currency={props.selectedAccount.baseCurrency}
        ref_id={props.success.depositId}
        className="nacepaypaybtn text-center"
        callback="true"
      ></div>
    );
  }

  useEffect(() => {
    let depositData = {
      accountPlatform: props.selectedAccount.platform,
      accountNumber: props.selectedAccount.number,
      depositCurrency: props.selectedAccount.baseCurrency,
      depositAmountSent: props.depositAmount,
      paymentGatewayService: props.paymentMethod.paymentGateway,
      depositType: props.paymentMethod.id,
      depositPaymentName: props.paymentMethod.method,
    };
    props.requestCryptoDeposit(depositData);
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      const script = document.createElement("script");
      script.src = CRYPTO_NACE.scriptUrl;
      script.async = true;
      document.body.appendChild(script);

      setPaymentPrepared(true);
    }
    if (Object.keys(props.errors).length > 0) {
      props.setPaymentResult("FAILED");
      props.nextStep("error");
    }
  }, [props]);

  useEffect(() => {
    const targetNode = nacePayDivRef.current;
    let observer;

    if (targetNode) {
      const callback = mutationsList => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            const img = targetNode.querySelector("img");
            if (img) {
              img.src = btnCryptoPay;
              img.style.width = "auto";
            }
          }
        }
      };

      observer = new MutationObserver(callback);
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [paymentPrepared]);

  return (
    <Row className="mx-1 mx-md-4 greyBg bottomBorders">
      <Col xs="12" className="py-5">
        Click the button below to deposit Crypto
      </Col>
      <Col xs="12" className="pb-5">
        {paymentPrepared && <PaymentComponent />}
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  requestCryptoDeposit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { requestCryptoDeposit })(Step);
