import React, { useState, useEffect } from "react";
import classes from "./Layout.module.css";
import Navbar from "../../components/Navigation/Navbar";
// import FooterHomepage from "../../components/Footer/FooterHomepage";
import SidebarUser from "../../components/Navigation/SidebarUser";
// import SidebarLogin from "../../components/Navigation/SidebarLogin";
import SidebarRegister from "../../components/Navigation/SidebarRegister";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  checkSuccessfulDeposit,
  logoutUser,
  updateLastLoginActiveTime,
} from "../../redux/actions/authActions";
import { Container, Row, Col } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";

const Layout = props => {
  const [isActive, setIsActive] = useState(false);

  const handleActivity = () => setIsActive(true);

  useEffect(() => {
    if (Object.keys(props.auth.user).length > 0) {
      if (!localStorage.lastLoginActiveTime)
        localStorage.setItem("lastLoginActiveTime", "0"); // Number of 15 seconds intervals

      // Event listeners for user activity
      window.addEventListener("mousemove", handleActivity);
      window.addEventListener("click", handleActivity);
      window.addEventListener("keypress", handleActivity);

      // Handle tab focus
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      });

      // Interval to update active time
      const interval = setInterval(() => {
        if (isActive && localStorage.lastLoginActiveTime) {
          localStorage.setItem(
            "lastLoginActiveTime",
            parseInt(localStorage.lastLoginActiveTime) + 1
          ); // Increment time

          updateLastLoginActiveTime(localStorage.lastLoginActiveTime).catch(
            err => console.log(err)
          );

          checkSuccessfulDeposit()
            .then(res => {
              if (res.data.notify) {
                NotificationManager.success(
                  "Your deposit has been successfully processed.",
                  "",
                  0
                );
              }
            })
            .catch(err => console.log(err));
        }
      }, 15000); // Update every 15s

      return () => {
        clearInterval(interval);
        window.removeEventListener("mousemove", handleActivity);
        window.removeEventListener("click", handleActivity);
        window.removeEventListener("keypress", handleActivity);
        document.removeEventListener("visibilitychange", handleActivity);
      };
    }
  }, [isActive]);

  const onLogoutClick = e => {
    e.preventDefault();
    props.logoutUser();
  };

  const HeaderWithRouter = withRouter(Navbar);

  let Sidebar = null;
  if (
    props.auth.isAuthenticated &&
    props.auth.user.status === "approved" &&
    window.location.pathname !== "/ideal"
  ) {
    Sidebar = !isMobileOnly ? withRouter(SidebarUser) : null;
  }
  //   if (
  //     (props.auth.user.identityDocumentUploaded ||
  //       localStorage.getItem("identityDocumentUploaded") === "true") &&
  //     (props.auth.user.poaDocumentUploaded ||
  //       localStorage.getItem("poaDocumentUploaded") === "true")
  //   ) {
  //     Sidebar = !isMobileOnly ? withRouter(SidebarUser) : null;
  //   }
  // }
  // if (window.location.href.includes("login")) {
  //   Sidebar = SidebarLogin;
  // }
  if (window.location.href.includes("register")) {
    Sidebar = !isMobileOnly ? SidebarRegister : null;
  }

  return (
    <>
      <Container style={{ height: "100vh" }} fluid>
        <Row className="h-100">
          {Sidebar ? (
            <Col
              className={[
                "sidebar px-0",
                window.location.href.includes("register") ? "register" : "",
              ].join(" ")}
            >
              <Sidebar />
            </Col>
          ) : null}
          <Col
            className={[
              classes.Content,
              !props.auth.isAuthenticated || window.location.pathname === "/"
                ? "mx-auto"
                : "",
            ].join(" ")}
          >
            <HeaderWithRouter logoutClicked={onLogoutClick} />
            {props.children}
          </Col>
        </Row>
      </Container>

      <NotificationContainer />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Layout);
