import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SAFECHARGE } from "../../../../config/config";
import { getChecksum } from "../../../../utils/utils";
import {
  logSafeChargeRedirectLink,
  requestWithdrawal,
} from "../../../../redux/actions/userActions";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  useEffect(() => {
    let withdrawData = {
      accountPlatform: props.selectedAccount.platform,
      accountNumber: props.selectedAccount.number,
      withdrawalCurrency: props.selectedAccount.baseCurrency,
      withdrawalAmount: props.withdrawAmount,
      paymentGatewayService: props.paymentMethod.paymentGateway,
      withdrawalType: props.paymentMethod.id,
      withdrawalPaymentName: props.paymentMethod.method,
    };
    props.requestWithdrawal(withdrawData);
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      const timeStamp = moment().format("YYYY-MM-DD.HH:mm:ss");

      const redirectLink = `${
        SAFECHARGE.paymentUrl
      }/withdrawal/withdraw.do?merchant_id=${
        SAFECHARGE.merchantId
      }&merchant_site_id=${
        SAFECHARGE.merchantSiteId
      }&user_token=auto&wd_currency=${
        props.selectedAccount.baseCurrency
      }&wd_amount=${props.withdrawAmount}&wd_min_amount=${
        props.withdrawAmount
      }&wd_max_amount=${props.withdrawAmount}&user_token_id=${
        props.auth.user.id
      }&version=4.0.0&theme_id=${
        isMobileOnly ? "313836" : "313676"
      }&time_stamp=${timeStamp}&checksum=${getChecksum(
        SAFECHARGE.secretKey,
        SAFECHARGE.merchantId,
        SAFECHARGE.merchantSiteId,
        "auto",
        props.selectedAccount.baseCurrency,
        props.withdrawAmount,
        props.withdrawAmount,
        props.withdrawAmount,
        props.auth.user.id,
        "4.0.0",
        isMobileOnly ? "313836" : "313676",
        timeStamp
      )}`;

      logSafeChargeRedirectLink(redirectLink)
        .then(() => {
          window.location.href = redirectLink;
        })
        .catch(err => console.log(err));
    }

    if (Object.keys(props.errors).length > 0) {
      props.setPaymentResult("FAILED");
      props.nextStep("error");
    }
  }, [props]);

  return (
    <Row className="mx-1 mx-md-4 greyBg bottomBorders">
      <Col className="py-5">
        <div className="wizardStepsParagraph">Please wait...</div>
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  requestWithdrawal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { requestWithdrawal })(Step);
