import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestCryptoWithdrawal } from "../../../../redux/actions/userActions";
import { Col, Row } from "react-bootstrap";

const Step = props => {
  useEffect(() => {
    handleRequestWithdrawal();
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      // console.log("props.success");
      // console.log(props.success);
      // window.location.href = props.success.redirectUrl;
      props.setPaymentResult("SUCCESS");
      props.nextStep("success");
    }

    if (Object.keys(props.errors).length > 0) {
      props.setPaymentResult("FAILED");
      props.nextStep("error");
    }
  }, [props]);

  const handleRequestWithdrawal = () => {
    let withdrawData = {
      accountPlatform: props.selectedAccount.platform,
      accountNumber: props.selectedAccount.number,
      withdrawalCurrency: props.selectedAccount.baseCurrency,
      withdrawalAmount: props.withdrawAmount,
      paymentGatewayService: props.paymentMethod.paymentGateway,
      withdrawalType: props.paymentMethod.id,
      withdrawalPaymentName: props.paymentMethod.method,
      cryptoCurrency: props.selectedCryptoCoin,
      cryptoTargetAddress: props.selectedCryptoAddress,
    };

    props.requestCryptoWithdrawal(withdrawData);
  };

  return (
    <Row className="mx-1 mx-md-4 greyBg bottomBorders">
      <Col className="py-5">
        <div className="wizardStepsParagraph">Please wait...</div>
      </Col>
    </Row>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  requestCryptoWithdrawal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { requestCryptoWithdrawal })(Step);
