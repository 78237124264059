import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Tabs,
  Tab,
  Card,
  Button,
  Spinner,
} from "react-bootstrap";
import {
  getLiveAccounts,
  getDemoAccounts,
} from "../../../redux/actions/userActions";
import AccountDetails from "../../../components/Modals/AccountDetails/AccountDetails";
import classes from "./Accounts.module.css";
import { NotificationManager } from "react-notifications";

const Accounts = props => {
  const [state, setState] = useState({
    showAddEditAccountDetailsModal: false,
    // showFundingModal: false,
    disableRequestAnAccountBtn: false,
    selectedAccount: {},
    loading: true,
  });
  const [accountsLive, setLiveAccounts] = useState([]);
  const [accountsDemo, setDemoAccounts] = useState([]);

  useEffect(() => {
    triggerGetAccounts();
  }, [props]);

  const triggerGetAccounts = () => {
    getLiveAccounts("all", "all", false)
      .then(res => {
        setLiveAccounts(res.data);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.log(err));
    getDemoAccounts()
      .then(res => {
        setDemoAccounts(res.data);
      })
      .catch(err => console.log(err));
  };

  const handleModalShow = (target, selectedAccount) => {
    if (target === "showAddEditAccountDetailsModal") {
      setState(prevState => {
        return { ...prevState, selectedAccount };
      });
    }

    setState(prevState => {
      return { ...prevState, [target]: true };
    });
  };

  const handleModalClose = target => {
    setState(prevState => {
      return { ...prevState, [target]: false };
    });
  };

  const cardStyleTypes = status => {
    if (status === "pending") return "opacity50";
    if (status === "rejected") return "rejectedAccBg text-white";

    return "";
  };

  const createNotification = type => {
    switch (type) {
      case "accountRequested":
        NotificationManager.success(
          "You have successfully requested a new account.",
          "New Account Request"
        );
        break;
      case "passwordChanged":
        NotificationManager.success(
          "You have successfully changed the password for your account.",
          "Password Change"
        );
        break;
      case "passwordChangeFailed":
        NotificationManager.error(
          "Something went wrong while changing your password. Please, try again.",
          "Error",
          5000
        );
        break;
      case "leverageChangeRequested":
        NotificationManager.success(
          "You have successfully requested leverage change for your account. Please wait for admin to approve it.",
          "Leverage Change"
        );
        break;
      default:
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="mx-md-4">
              Trading Accounts{" "}
              {state.loading ? <Spinner animation="border" /> : null}
              <Button
                type="button"
                variant="secondary"
                className="blueButton float-right"
                style={{ width: "auto", fontWeight: "normal", marginTop: 0 }}
                onClick={() =>
                  handleModalShow("showAddEditAccountDetailsModal", "")
                }
              >
                Request an account
              </Button>
            </h1>
          </Col>
        </Row>
        <Row className="mx-md-4">
          <Col className="px-0">
            <Tabs defaultActiveKey="live" transition={false} id="account-tabs">
              <Tab eventKey="live" title="LIVE" className="p-4">
                {Object.entries(accountsLive).map(account => (
                  <div
                    key={account[1]._id}
                    className="cardWithLeftBorder mx-1 mx-md-3 my-2"
                  >
                    <div
                      className={[
                        "cardBorder",
                        account[1].status === "pending" ? "orange" : "red",
                      ].join(" ")}
                    />
                    <Card
                      className={[
                        "customCardsStyle",
                        classes.AccountCard,
                        cardStyleTypes(account[1].status),
                      ].join(" ")}
                    >
                      <Card.Body>
                        <div style={{ height: "44px" }}>
                          <div className="float-left">
                            <div className="cardCaptionTitle pt-1">
                              ACCOUNT NAME
                            </div>
                            {account[1].nickname}
                          </div>
                          <div className="float-right">
                            <div className="cardFlag accType live">LIVE</div>
                            <div className="cardFlag accPlatform">
                              {account[1].platform}
                            </div>
                          </div>
                        </div>
                        <div style={{ height: "40px" }}>
                          <div className="float-left">
                            <div className="cardCaptionTitle">
                              ACCOUNT NUMBER
                            </div>
                            {account[1].number || "-"}
                          </div>
                          <div className="float-left pl-3">
                            <div className="cardCaptionTitle">LEVERAGE</div>
                            {`1:${account[1].leverage}`}
                          </div>
                        </div>
                        <div style={{ height: "40px" }}>
                          <div className="float-left">
                            <div className="cardCaptionTitle">BALANCE</div>
                            {account[1].balance
                              ? parseFloat(account[1].balance).toFixed(2)
                              : 0}{" "}
                          </div>
                          <div className="float-left cardFlag cardCurrencyFlag cardCurrencyFlagSmaller">
                            {account[1].baseCurrency}
                          </div>
                        </div>
                        {account[1].status === "approved" ? (
                          <div className="d-flex justify-content-around">
                            <Button
                              className="cardButton"
                              style={{
                                backgroundColor: "var(--color-dark-gray4)",
                              }}
                              onClick={() =>
                                handleModalShow(
                                  "showAddEditAccountDetailsModal",
                                  account[1]
                                )
                              }
                              disabled={account[1].status === "pending"}
                            >
                              SETTINGS
                            </Button>
                            <Button
                              className="cardButton"
                              style={{
                                backgroundColor: "var(--color-dark-blue1)",
                              }}
                              as={Link}
                              to={`/deposit-funds/account/${account[1].number}`}
                              disabled={account[1].status === "pending"}
                            >
                              FUND NOW
                            </Button>
                          </div>
                        ) : null}
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </Tab>
              <Tab eventKey="demo" title="DEMO" className="p-4">
                {Object.entries(accountsDemo).map(account => (
                  <div
                    key={account[1]._id}
                    className="cardWithLeftBorder mx-1 mx-md-3 my-2"
                  >
                    <div
                      className={[
                        "cardBorder",
                        account[1].status === "pending" ? "orange" : "red",
                      ].join(" ")}
                    />
                    <Card
                      className={[
                        "customCardsStyle",
                        classes.AccountCard,
                        cardStyleTypes(account[1].status),
                      ].join(" ")}
                    >
                      <Card.Body>
                        <div style={{ height: "44px" }}>
                          <div className="float-left">
                            <div className="cardCaptionTitle pt-1">
                              ACCOUNT NAME
                            </div>
                            {account[1].nickname}
                          </div>
                          <div className="float-right">
                            <div className="cardFlag accType demo">DEMO</div>
                            <div className="cardFlag accPlatform">
                              {account[1].platform}
                            </div>
                          </div>
                        </div>
                        <div style={{ height: "40px" }}>
                          <div className="float-left">
                            <div className="cardCaptionTitle">
                              ACCOUNT NUMBER
                            </div>
                            {account[1].number || "-"}
                          </div>
                          <div className="float-left pl-3">
                            <div className="cardCaptionTitle">LEVERAGE</div>
                            {`1:${account[1].leverage}`}
                          </div>
                        </div>
                        <div style={{ height: "40px" }}>
                          <div className="float-left">
                            <div className="cardCaptionTitle">BALANCE</div>
                            {account[1].balance
                              ? parseFloat(account[1].balance).toFixed(2)
                              : 0}{" "}
                          </div>
                          <div className="float-left cardFlag cardCurrencyFlag cardCurrencyFlagSmaller">
                            {account[1].baseCurrency}
                          </div>
                        </div>
                        {/*<div className="d-flex justify-content-around">*/}
                        {/*  <Button*/}
                        {/*    className="cardButton"*/}
                        {/*    style={{*/}
                        {/*      backgroundColor: "var(--color-dark-gray4)",*/}
                        {/*    }}*/}
                        {/*    onClick={() =>*/}
                        {/*      handleModalShow(*/}
                        {/*        "showAddEditAccountDetailsModal",*/}
                        {/*        account[1]*/}
                        {/*      )*/}
                        {/*    }*/}
                        {/*  >*/}
                        {/*    SETTINGS*/}
                        {/*  </Button>*/}
                        {/*  <Button*/}
                        {/*    className="cardButton"*/}
                        {/*    style={{*/}
                        {/*      backgroundColor: "var(--color-dark-blue1)",*/}
                        {/*    }}*/}
                        {/*    as={Link}*/}
                        {/*    to="/deposit-funds"*/}
                        {/*  >*/}
                        {/*    FUND NOW*/}
                        {/*  </Button>*/}
                        {/*</div>*/}
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>

      {/*{state.showFundingModal ? (*/}
      {/*  <PaymentModal*/}
      {/*    handleModalClose={handleModalClose}*/}
      {/*    account={state.selectedAccount}*/}
      {/*  />*/}
      {/*) : null}*/}
      {state.showAddEditAccountDetailsModal ? (
        <AccountDetails
          triggerGetAccounts={triggerGetAccounts}
          handleModalClose={handleModalClose}
          createNotification={createNotification}
          account={state.selectedAccount}
        />
      ) : null}
    </>
  );
};

Accounts.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Accounts);
