import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";

const success = props => {
  return (
    <Row className="mx-1 mx-md-4 py-5 greyBg bottomBorders wizardComplete">
      <Col>
        <div className="circle success checkmarkAnimation">
          <FaCheck className="checkmark" />
        </div>

        {props.children}

        {/*<Button className="completeButton success">BACK TO DASHBOARD</Button>*/}
      </Col>
    </Row>
  );
};

export default success;
