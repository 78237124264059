import { marketingCode } from "../../redux/actions/userActions";

const Marketing = props => {
  let code = props.match.params.code.trim();

  if (code.length === 36) {
    const data = {
      code,
      referrer: document.referrer,
    };

    marketingCode(data)
      .then(() => {
        localStorage.setItem("marketing", "freshworks");
        window.location.href = "/";
      })
      .catch(err => console.log(err));
  } else window.location.href = "/";

  return null;
};

export default Marketing;
