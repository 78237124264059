import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row, Card, Button, Spinner } from "react-bootstrap";
import { getWalletAccounts } from "../../../redux/actions/userActions";
import WalletDetails from "../../../components/Modals/WalletDetails/WalletDetails";
import classes from "./Accounts.module.css";
import { NotificationManager } from "react-notifications";

const Accounts = props => {
  const [state, setState] = useState({
    addWalletErrors: "",
    showAddWalletModal: false,
    loading: true,
    showRequestWallet: false,
  });
  const [walletAccounts, setWalletAccounts] = useState([]);

  useEffect(() => {
    triggerGetWalletAccounts();
  }, [props]);

  const triggerGetWalletAccounts = () => {
    getWalletAccounts()
      .then(res => {
        setWalletAccounts(res.data);
        let showRequestWallet = res.data.length < 3;
        setState(prevState => {
          return {
            ...prevState,
            showRequestWallet,
            loading: false,
          };
        });
      })
      .catch(err => console.log(err));
  };

  const handleModalShow = target => {
    setState(prevState => {
      return { ...prevState, [target]: true };
    });
  };

  const handleModalClose = target => {
    setState(prevState => {
      return { ...prevState, [target]: false };
    });
  };

  const cardStyleTypes = status => {
    if (status === "pending") return "opacity50";
    if (status === "rejected") return "rejectedAccBg text-white";

    return "";
  };

  const createNotification = type => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "walletRequested":
        NotificationManager.success(
          "You have successfully requested a wallet.",
          "New Wallet Request"
        );
        break;
      default:
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="mx-md-4">
              Wallet Accounts{" "}
              {state.loading ? <Spinner animation="border" /> : null}
              {state.showRequestWallet ? (
                <Button
                  type="button"
                  variant="secondary"
                  className="blueButton float-right mr-3"
                  style={{ width: "auto", fontWeight: "normal", marginTop: 0 }}
                  onClick={() => handleModalShow("showAddWalletModal")}
                >
                  Create a wallet
                </Button>
              ) : null}
            </h1>
          </Col>
        </Row>
        <Row className="mx-md-4">
          <Col className="py-3 tab-pane">
            {Object.entries(walletAccounts).map(account => (
              <div
                key={account[1]._id}
                className="cardWithLeftBorder mx-1 mx-md-3 my-2"
              >
                <div
                  className={[
                    "cardBorder",
                    account[1].status === "pending" ? "orange" : "red",
                  ].join(" ")}
                />
                <Card
                  className={[
                    "customCardsStyle",
                    classes.AccountCard,
                    cardStyleTypes(account[1].status),
                  ].join(" ")}
                >
                  <Card.Body>
                    <div style={{ height: "44px" }}>
                      <div className="float-left">
                        <div className="cardCaptionTitle pt-1">WALLET NAME</div>
                        {account[1].nickname}
                      </div>
                    </div>
                    <div style={{ height: "40px" }}>
                      <div className="float-left">
                        <div className="cardCaptionTitle">BALANCE</div>
                        {account[1].balance
                          ? parseFloat(account[1].balance).toFixed(2)
                          : 0}{" "}
                      </div>
                      <div className="float-left cardFlag cardCurrencyFlag cardCurrencyFlagSmaller">
                        {account[1].baseCurrency}
                      </div>
                    </div>
                    {account[1].status === "approved" ? (
                      <div className="d-flex justify-content-around">
                        <Button
                          className="cardButton"
                          style={{
                            backgroundColor: "var(--color-dark-blue1)",
                          }}
                          as={Link}
                          to={`/deposit-funds/account/${account[1].number}`}
                          disabled={account[1].status === "pending"}
                        >
                          FUND NOW
                        </Button>
                      </div>
                    ) : null}
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Col>
        </Row>
      </Container>

      {/*{state.showFundingModal ? (*/}
      {/*  <PaymentModal*/}
      {/*    handleModalClose={handleModalClose}*/}
      {/*    account={state.selectedAccount}*/}
      {/*  />*/}
      {/*) : null}*/}
      {state.showAddWalletModal ? (
        <WalletDetails
          triggerGetWalletAccounts={triggerGetWalletAccounts}
          handleModalClose={handleModalClose}
          createNotification={createNotification}
        />
      ) : null}
    </>
  );
};

Accounts.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Accounts);
