import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { updateEmploymentIncomeWeb } from "../../../../redux/actions/authActions";
import classes from "../RegisterNew.module.css";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!!localStorage.userData) {
      setUserData(prevState => {
        return {
          ...prevState,
          ...JSON.parse(localStorage.userData),
        };
      });
    }
  }, []);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 2) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(1);
      }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleChange = (value, target) => {
    setUserData(prevState => {
      return {
        ...prevState,
        [target]: value,
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    setDisableButton(true);

    const employmentIncome = {
      step: 2,
      subStep: 6,
      userId: props.auth.user.id,
      employmentStatus: userData.employmentStatus,
      industry: userData.industry,
      levelOfEducation: userData.levelOfEducation,
    };

    props.updateEmploymentIncomeWeb(employmentIncome);
  };

  return (
    <>
      {isMobileOnly ? (
        <>
          <h1 className={classes.h1}>Personal details</h1>
          <h2 className={classes.h2}>Employment information</h2>
        </>
      ) : (
        <h1 className={classes.h1}>Employment information</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col} className="mb-0">
                <Form.Label className={classes.label}>
                  Employment Status
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.employmentStatus &&
                  userData.employmentStatus === "Employed"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Employed", "employmentStatus")}
              >
                Employed
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.employmentStatus &&
                  userData.employmentStatus === "Self-employed"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() =>
                  handleChange("Self-employed", "employmentStatus")
                }
              >
                Self-employed
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.employmentStatus &&
                  userData.employmentStatus === "Retired"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Retired", "employmentStatus")}
              >
                Retired
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.employmentStatus &&
                  userData.employmentStatus === "Unemployed"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Unemployed", "employmentStatus")}
              >
                Unemployed
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.employmentStatus &&
                  userData.employmentStatus === "Student"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Student", "employmentStatus")}
              >
                Student
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.employmentStatus &&
                  userData.employmentStatus === "Other"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Other", "employmentStatus")}
              >
                Other
              </Form.Group>
            </Form.Row>
            <Form.Text style={{ color: "red" }}>
              {errors.employmentStatus}
            </Form.Text>

            <Form.Row>
              <Form.Group as={Col} className="mt-4 mb-0">
                <Form.Label className={classes.label}>Industry</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.industry && userData.industry === "Finance"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Finance", "industry")}
              >
                Finance
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.industry && userData.industry === "IT"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("IT", "industry")}
              >
                IT
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.industry && userData.industry === "Construction"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Construction", "industry")}
              >
                Construction
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.industry && userData.industry === "Education"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Education", "industry")}
              >
                Education
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.industry && userData.industry === "Healthcare"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Healthcare", "industry")}
              >
                Healthcare
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.industry && userData.industry === "Other"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Other", "industry")}
              >
                Other
              </Form.Group>
            </Form.Row>
            <Form.Text style={{ color: "red" }}>{errors.industry}</Form.Text>

            <Form.Row>
              <Form.Group as={Col} className="mt-4 mb-0">
                <Form.Label className={classes.label}>
                  Level of education
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.levelOfEducation &&
                  userData.levelOfEducation === "High School"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("High School", "levelOfEducation")}
              >
                High School
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.levelOfEducation &&
                  userData.levelOfEducation === "College"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("College", "levelOfEducation")}
              >
                College
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.levelOfEducation &&
                  userData.levelOfEducation === "University (BSc)"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() =>
                  handleChange("University (BSc)", "levelOfEducation")
                }
              >
                University (BSc)
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.levelOfEducation &&
                  userData.levelOfEducation === "University (MSc)"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() =>
                  handleChange("University (MSc)", "levelOfEducation")
                }
              >
                University (MSc)
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.levelOfEducation &&
                  userData.levelOfEducation === "PhD"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("PhD", "levelOfEducation")}
              >
                PhD
              </Form.Group>
              <Form.Group
                as={Col}
                className={[
                  classes.smallChoice,
                  userData.levelOfEducation &&
                  userData.levelOfEducation === "Other"
                    ? classes.choiceBoxActive
                    : "",
                ].join(" ")}
                onClick={() => handleChange("Other", "levelOfEducation")}
              >
                Other
              </Form.Group>
            </Form.Row>
            <Form.Text style={{ color: "red" }}>
              {errors.levelOfEducation}
            </Form.Text>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>
            <Button
              variant="link"
              className="simpleBackButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="blueButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  updateEmploymentIncomeWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateEmploymentIncomeWeb })(Step);
